<footer>
    <div class="container">
      <div class="row">
        <div class="col-md-3 col-sm-3">
          <div class="widget">
            <h5 class="widgetheading">Digimiles</h5>
            <p>
              Passion, Dedication, Innovation, Discipline are the four
              pillars <br />
              where we stand.
            </p>
            <img src="assets/img/bni-logo.png" style="width: 50%; margin-bottom: 10px" alt="Proud BNI member logo" class="img-fluid">
          </div>
        </div>
        <div class="col-md-3 col-sm-3">
          <div class="widget">
            <h5 class="widgetheading">Quick Links</h5>
            <ul class="link-list">
              <li><a routerLink="about-us">About Us</a></li>
              <li><a href="https://pmny.in/8IoqIIRq1sBX" target="_blank">Payment</a></li>
              <li><a routerLink="terms-and-conditions">Terms and Conditions</a></li>
              <li><a routerLink="privacy-policy">Privacy Policy </a></li>
            </ul>
          </div>
        </div>
        <div class="col-md-3 col-sm-3">
          <div class="widget">
            <h5 class="widgetheading">Social Media</h5>
            <ul class="link-list">
              <li>
                <a target="_blank" href="https://www.facebook.com/digimiles.chd"
                  >Facebook</a
                >
              </li>
              <li>
                <a target="_blank"
                  href="https://www.instagram.com/digimiles_official/?igshid=1919t99nht9um"
                  >Instagram</a
                >
              </li>
              <li>
                <a target="_blank"
                  href="https://www.youtube.com/channel/UCw8Slz8irYING-yzAf7XZkg"
                  >Youtube</a
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-3 col-sm-3">
          <div class="widget">
            <h5 class="widgetheading">Our Contact</h5>
            <address>
              <strong>
                D-107, Phase 7, Industrial Area, Sector 73, Pincode: 160054</strong
              >
            </address>
            <p>
              <i class="icon-phone"></i>9041020709<br />
              <i class="icon-envelope-alt"></i> info&#64;digimiles.net
            </p>
          </div>
        </div>
      </div>
    </div>
    <div id="sub-footer">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 text-center">
            <div class="copyright">
              <p>
                <span
                  >&copy; Copyright 2020 by Digimiles. All rights reserved.
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>