<section class="wrapper">
    <div class="container">
      <div class="row mt-50">
        <div class="col-12">
          <ul class="services">
            <li [routerLinkActive]="['active']">
              <a (click)="changeRouteTo(['/', 'services', 'web-designing'])"
                >Web Designing</a
              >
            </li>
            <li [routerLinkActive]="['active']">
              <a (click)="changeRouteTo(['/', 'services', 'it-solutions'])"
                >IT Solutions</a
              >
            </li>
            <li [routerLinkActive]="['active']">
              <a
                (click)="
                  changeRouteTo(['/', 'services', 'social-media-marketing'])
                "
                >Social Media Marketing</a
              >
            </li>
            <li [routerLinkActive]="['active']">
              <a (click)="changeRouteTo(['/', 'services', 'app-development'])"
                >App Development</a
              >
            </li>
            <li [routerLinkActive]="['active']">
              <a (click)="changeRouteTo(['/', 'services', 'creative-design'])"
                >Creative Design</a
              >
            </li>
            <li [routerLinkActive]="['active']">
              <a (click)="changeRouteTo(['/', 'services', 'brand-management'])"
                >Brand Management</a
              >
            </li>
          </ul>
        </div>
      </div>
      <div class="row showcase-section">
        <div class="col-md-6">
          <img
            class="img-responsive"
            src="assets/img//services/smm.jpg"
            alt=""
          />
        </div>
  
        <div class="col-md-6">
            <h3>Social Media Marketing</h3>
            <p>As social media becomes the one of most influential marketing channels today, businesses realize the need for establishing themelves strongly on the various channels. Social media marketing is an effective marketing strategy that harnesses the power of social channels to increase engagement, boost sales and build your business brand. It enables a business to capitalize on popularity of platforms such as Facebook, Twitter, Instagram, YouTube and more. With the effective use of content across these channels, the business gets the opportunity to generate interest in its products and services and engage the customers as well.</p>
        </div>
      </div>
  
      <div class="row">
        <div class="col-md-6">
          <div>
            <h3> Results And Growth</h3>
            <p>Most businesses online miss the true value that social media brings to the table. It can be both a lead generator and a great engagement strategy to increase brand awareness. Our social media marketing campaigns are built with lead generation and retention in mind to ensure that your audience is tuned into your message. The optimizations we make will improve your paid social results. You will see an increase in your social media following, brand awareness, and conversions. This results in making more money.</p>
            <p>We help our clients to generate social media content that will grow their audience organically. </p>
          </div>
        </div>
        <div class="col-md-6">
          <img
            class="img-responsive"
            src="assets/img//services/smm2.jpg"
            alt=""
          />
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-center">
          <a (click)="changeRouteTo(['/', 'contact'])"
            ><button type="button" class="btn btn-secondary mt-5 my-5">
              Contact Us
            </button></a
          >
        </div>
      </div>
    </div>
  </section>
  