<section class="wrapper">
    <div class="container">
      <div class="row mt-50">
        <div class="col-12">
          <ul class="services">
            <li [routerLinkActive]="['active']">
              <a (click)="changeRouteTo(['/', 'services', 'web-designing'])"
                >Web Designing</a
              >
            </li>
            <li [routerLinkActive]="['active']">
              <a (click)="changeRouteTo(['/', 'services', 'it-solutions'])"
                >IT Solutions</a
              >
            </li>
            <li [routerLinkActive]="['active']">
              <a
                (click)="
                  changeRouteTo(['/', 'services', 'social-media-marketing'])
                "
                >Social Media Marketing</a
              >
            </li>
            <li [routerLinkActive]="['active']">
              <a (click)="changeRouteTo(['/', 'services', 'app-development'])"
                >App Development</a
              >
            </li>
            <li [routerLinkActive]="['active']">
              <a (click)="changeRouteTo(['/', 'services', 'creative-design'])"
                >Creative Design</a
              >
            </li>
            <li [routerLinkActive]="['active']">
              <a (click)="changeRouteTo(['/', 'services', 'brand-management'])"
                >Brand Management</a
              >
            </li>
          </ul>
        </div>
      </div>
      <div class="row showcase-section">
        <div class="col-md-6">
          <img
            class="img-responsive"
            src="assets/img/services/web.jpg"
            alt=""
          />
        </div>
  
        <div class="col-md-6">
            <h3>Web Designing</h3>
            <p>We are a dedicated web design company and our focus is designing your website to suit your individual requirements. The look and feel and user experiance of a website is one of the most important elements. Our experience and knowledge will ensure that your website is exactly what you envision it to be.</p>
            <p>Our web development team looks beyond the visual effect of the website and takes other factors into consideration such as the user-friendliness of the website, ease of maintenance and adhering to the best practices and website development standards.</p>
        </div>
      </div>
  
      <div class="row">
        <div class="col-md-6">
          <div>
            <h3> Implementation & Design</h3>
            <p>We start implementing the planned changes to your site and incorporating the new design.It’s crucial to build a website that conveys the right message to the buyer, regardless of how they arrive on your site. We leverage responsive design to adjust visual elements on a variety of screen sizes, leading to greater conversion rates. </p>
               <p>We offer comprehensive website designing services to ensure that the entire needs of business are addressed at a single destination. Whether it is e-commerce stores, corporate websites, blogs, or landing pages, we create the most amazing ones.</p>
          </div>
        </div>
        <div class="col-md-6">
          <img
            class="img-responsive"
            src="assets/img/services/web2.jpg"
            alt=""
          />
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-center">
          <a (click)="changeRouteTo(['/', 'contact'])"
            ><button type="button" class="btn btn-secondary mt-5 my-5">
              Contact Us
            </button></a
          >
        </div>
      </div>
    </div>
  </section>
  