<app-header></app-header>
<div class="query_section">
  <p-dialog
    header="Reach Us For Any Query"
    [maximizable]="true"
    [closable]="true"
    position="right"
    [modal]="true"
    [breakpoints]="{ '960px': '75vw', '640px': '80vw' }"
    [(visible)]="display"
    [style]="{ width: '40vw' }"
  >
    <form [formGroup]="userform" (ngSubmit)="onSubmit(userform.value)">
      <div class="p-fluid forms-grid" style="margin: 1em 0">
        <div>
          <label for="name">Name*</label>
          <input
            pInputText
            type="text"
            formControlName="name"
            placeholder="Required"
          />
          <p-message
            severity="error"
            text="name is required"
            *ngIf="
              !userform.controls['name'].valid &&
              userform.controls['name'].dirty
            "
          ></p-message>
        </div>
        <div>
          <label for="email">Email*</label>
          <input
            pInputText
            type="email"
            formControlName="email"
            placeholder="Required"
            [email]="true"
          />
        </div>
        <div>
          <label for="number">Phone Number*</label>
          <input
            pInputText
            type="number"
            formControlName="number"
            placeholder="Required"
          />
        </div>

        <div>
          <label for="query">Query For?</label>
          <p-dropdown
            formControlName="query"
            placeholder="Select option*"
            name="query"
            [options]="queries"
            optionLabel="name"
          ></p-dropdown>
        </div>

        <div>
          <label for="message">Message</label>
          <textarea pInputText type="text" formControlName="message"></textarea>
        </div>
        <div>
          <button
            pButton
            type="submit"
            label="Submit"
            [disabled]="!userform.valid"
          ></button>
        </div>
      </div>
    </form>
  </p-dialog>
  <p-toast [style]="{ marginTop: '80px' }"></p-toast>
  <button
    pButton
    type="button"
    (click)="showDialog()"
    class="btn-primary enquiry"
  >
    Ask Your Query?
  </button>
</div>

<router-outlet></router-outlet>
<app-footer></app-footer>
<a class="whatsapp" target="_blank" href="//api.whatsapp.com/send?phone=919041010709&text"
  ><img
    src="assets/img/whatsapp-icon.png"
    width="70"
    height="70"
  />
</a>
<a href="#" class="scrollup"><i class="fa fa-angle-up active"></i></a>

