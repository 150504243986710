<section class="wrapper">
  <div class="container">
    <div class="row mt-50">
      <div class="col-12">
        <ul class="services">
          <li [routerLinkActive]="['active']">
            <a (click)="changeRouteTo(['/', 'services', 'web-designing'])"
              >Web Designing</a
            >
          </li>
          <li [routerLinkActive]="['active']">
            <a (click)="changeRouteTo(['/', 'services', 'it-solutions'])"
              >IT Solutions</a
            >
          </li>
          <li [routerLinkActive]="['active']">
            <a
              (click)="
                changeRouteTo(['/', 'services', 'social-media-marketing'])
              "
              >Social Media Marketing</a
            >
          </li>
          <li [routerLinkActive]="['active']">
            <a (click)="changeRouteTo(['/', 'services', 'app-development'])"
              >App Development</a
            >
          </li>
          <li [routerLinkActive]="['active']">
            <a (click)="changeRouteTo(['/', 'services', 'creative-design'])"
              >Creative Design</a
            >
          </li>
          <li [routerLinkActive]="['active']">
            <a (click)="changeRouteTo(['/', 'services', 'brand-management'])"
              >Brand Management</a
            >
          </li>
        </ul>
      </div>
    </div>
    <div class="row showcase-section">
      <div class="col-md-6">
        <img
          class="img-responsive"
          src="assets/img/services/brand.jpg"
          alt=""
        />
      </div>

      <div class="col-md-6">
        <h3 class="mt-0">Brand Management</h3>
        <p>
          Branding involves more than just a name and a creative logo, but a
          strategy behind your identity and the unique services your business
          provides. First impressions can often make or break a business, and
          that's why in this digitally growing world brands should be developed
          with a digital-first mentality to perform first and foremost in the
          modern digital landscape. All activities come under the creative
          digital performance.
        </p>
        <p>
          With perfect brand management services at Digimiles, you can begin to
          put your best foot forward. Our dedicated digital brand management
          team will use our expertise to help you establish your identity and
          design the right messaging to promote your brand.
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <div>
          <h3 class="mt-0">Video Shoots</h3>

          <p>
            The best marketing videos don’t just happen – they’re a result of
            meticulous planning and preparation.Helping famous businesses and
            brands to reach and influence their audiences using the power of
            video.We pride ourselves on offering a great place to work. The team
            is friendly, the coffee is good and we give everyone the support
            they need to shine. If you’re bright, ambitious and appreciate a
            good team night out, you’ll fit right in. The better the video
            marketing strategy, the better the returns. That’s a fact.
          </p>
          <p>
            Your business likely offers different services or products, not just
            one, so you’ll need different videos for each of these.
          </p>
        </div>
      </div>
      <div class="col-md-6">
        <img
          class="img-responsive"
          src="assets/img/services/video-shoot.jpg"
          alt=""
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-center">
        <a (click)="changeRouteTo(['/', 'contact'])"
          ><button type="button" class="btn btn-secondary mt-5 my-5">
            Contact Us
          </button></a
        >
      </div>
    </div>
  </div>
</section>
