import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-app-development',
  templateUrl: './app-development.component.html',
  styleUrls: ['./app-development.component.css']
})
export class AppDevelopmentComponent implements OnInit {

  constructor(private route : Router) { }

  ngOnInit(): void {
    
  }

  changeRouteTo(arr){
    this.route.navigate(arr);
  }

}
