<div id="wrapper">
  <section id="content">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="about-logo">
            <h3>Our Portfolio</h3>
            <p>
              Our development experience comes from working extensively with
              various industry focus businesses, such as health care, education,
              online retail, non-profit organizations, mortgage, real estate,
              entertainment, manufacturing, and professional services.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <p-tabView>

          <p-tabPanel header="Website Development">
            <div class="row">
              <div
                class="col-md-4 col-sm-6 col-xs-12 p-5"
                *ngFor="let images of webGallery"
              >
                <div class="gallery-item">
                  <div class="gallery-thumb">
                   <a href="{{ images.link }}" target="_blank" ><img
                    src="{{ images.img }}"
                    class="img-responsive"
                    alt="1st gallery Thumb"
                  /></a> 
                    <div class="image-overlay"></div>
                    <a href="{{ images.img }}" target="_blank" class="gallery-zoom"
                      ><i class="fa fa-eye"></i
                    ></a>
                    <a href="{{ images.link }}" target="_blank" class="gallery-link"
                      ><i class="fa fa-link"></i
                    ></a>
                  </div>
                </div>
              </div>
            </div>
          </p-tabPanel>
          <p-tabPanel header="Graphic Design">
            <div class="row">
              <div
                class="col-md-4 col-sm-6 col-xs-12 p-5"
                *ngFor="let images of graphics"
              >
                <div class="gallery-item">
                  <div class="gallery-thumb">
                    <img
                      src="{{ images.img }}"
                      class="img-responsive"
                      alt="1st gallery Thumb"
                    />
                    <div class="image-overlay"></div>
                    <a href="{{ images.img }}" class="gallery-zoom"
                      ><i class="fa fa-eye"></i
                    ></a>
                    <a href="{{ images.img }}" class="gallery-link"
                      ><i class="fa fa-link"></i
                    ></a>
                  </div>
                </div>
              </div>
            </div>
          </p-tabPanel>
          <p-tabPanel header="Video Shoots">
            <div class="row">
              <div
                class="col-md-4 col-sm-6 col-xs-12 p-5"
                *ngFor="let images of videoGallery"
              >
                <div class="gallery-item">
                  <div class="gallery-thumb">
                    <img
                      src="{{ images.img }}"
                      class="img-responsive"
                      alt="1st gallery Thumb"
                    />
                    <div class="image-overlay"></div>
                    <a href="{{ images.img }}" class="gallery-zoom"
                      ><i class="fa fa-eye"></i
                    ></a>
                    <a href="{{ images.link }}" class="gallery-link"
                      ><i class="fa fa-link"></i
                    ></a>
                  </div>
                </div>
              </div>
            </div>
          </p-tabPanel>
          <!-- <p-tabPanel header="Seo">
            <div class="row">
              <div
                class="col-md-4 col-sm-6 col-xs-12 p-5"
                *ngFor="let images of seo"
              >
                <div class="gallery-item">
                  <div class="gallery-thumb">
                    <img
                      src="{{ images.img }}"
                      class="img-responsive"
                      alt="1st gallery Thumb"
                    />
                    <div class="image-overlay"></div>
                    <a href="{{ images.img }}" class="gallery-zoom"
                      ><i class="fa fa-eye"></i
                    ></a>
                    <a href="{{ images.link }}" class="gallery-link"
                      ><i class="fa fa-link"></i
                    ></a>
                  </div>
                </div>
              </div>
            </div>
          </p-tabPanel> -->
          <p-tabPanel header="Social Media Management">
            <div class="row">
              <div
                class="col-md-4 col-sm-6 col-xs-12 p-5"
                *ngFor="let images of smm"
              >
                <div class="gallery-item">
                  <div class="gallery-thumb">
                    <img
                      src="{{ images.img }}"
                      class="img-responsive"
                      alt="1st gallery Thumb"
                    />
                    <div class="image-overlay"></div>
                    <a href="{{ images.img }}" class="gallery-zoom"
                      ><i class="fa fa-eye"></i
                    ></a>
                    <a href="{{ images.link }}" class="gallery-link"
                      ><i class="fa fa-link"></i
                    ></a>
                  </div>
                </div>
              </div>
            </div>
          </p-tabPanel>
        </p-tabView>
      </div>
    </div>
  </div>
</div>
