import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-creative-design',
  templateUrl: './creative-design.component.html',
  styleUrls: ['./creative-design.component.css']
})
export class CreativeDesignComponent implements OnInit {

  constructor( private route: Router) { }

  ngOnInit(): void {
  }
  changeRouteTo(arr){
    this.route.navigate(arr);
  }

}
