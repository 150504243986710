<section class="pt-4 mt-3">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h2 class="text-center">Blogs</h2>
            </div>
        </div>
        <div class="row mt-3 mb-5"  >
            <div class="col-md-4 mt-3" *ngFor="let blog of blogs">
                <div class="card rounded ">
                    <a [routerLink]="['/blog-details', blog.id]">
                    <img src="{{blog.image}}" class="img-fluid rounded-top" alt="{{alt_text}}">
                    </a>
                    <div class="card-body p-3">
                        <h4 class="card-text mt-0 mb-0">{{blog.title}}  
                        </h4>
                           <a [routerLink]="['/blog-details', blog.id]" class="btn btn-primary text-center mt-3 mb-0"> Read More</a> 
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>