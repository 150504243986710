import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-brand-management',
  templateUrl: './brand-management.component.html',
  styleUrls: ['./brand-management.component.css']
})
export class BrandManagementComponent implements OnInit {

  constructor(private route : Router) { }

  ngOnInit(): void {
  }
  changeRouteTo(arr){
    this.route.navigate(arr);
  }

}
