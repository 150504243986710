import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { MessageService } from 'primeng/api';
import { HttpService } from 'src/app/services/http.service';
import Typewriter from 't-writer.js'
@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css']
})
export class LandingPageComponent implements OnInit {
  registerationForm:FormGroup;
  
  constructor(private formBuilder: FormBuilder,
    private httpservice: HttpService,
    private messageService: MessageService) { }

  
  ngOnInit(): void {
    this.registerationForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)    ]],
      name: ['', Validators.required],
      phone_number: ['', [Validators.required, Validators.pattern('[0-9 ]{10,15}' )]],
      type: ['Web and App Development']

    }); 
    const target = document.querySelector('.tw');
    const writer = new Typewriter(target, {
      loop: true, 
      typeColor: '#54bb83' ,
      // cursorColor: 'red'
    })
    writer
      .strings(
        400,
        "Make More Sales Online?",
        "Boost your Business Identity?", 
        "Attract More Customers?",
        "Improve your Business Visibility?",
        "Make more Profit?"
      )
      .start()
  }
  slidesStore = [
    {
      src: 'https://www.goprotoz.com/campaign/ui-ux/in/images/GoProtoz/client/icici-bank-logo.png',
      alt: 'Client Logo'
    },
    {
      src: 'https://www.goprotoz.com/campaign/ui-ux/in/images/GoProtoz/client/paytm-money-logo@2x.png',
      alt: 'Client Logo'
    },
    {
      src: 'https://www.goprotoz.com/campaign/ui-ux/in/images/GoProtoz/client/tata-global-beverages.png',
      alt: 'Client Logo'
    },
    {
      src: 'https://www.goprotoz.com/campaign/ui-ux/in/images/GoProtoz/client/mahindra-comviva-logo.png',
      alt: 'Client Logo'
    },
    {
      src: 'https://www.goprotoz.com/campaign/ui-ux/in/images/GoProtoz/client/cakezone-logo.svg',
      alt: 'Client Logo'
    },
    {
      src: 'https://www.goprotoz.com/campaign/ui-ux/in/images/GoProtoz/client/learnapp-logo.svg',
      alt: 'Client Logo'
    }
]
   customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    pullDrag: false,
    dots: false,
    margin: 20,
    autoplay: true,
    autoplayHoverPause: true,
    navSpeed: 700,
    navText : ["<i class='fa fa-arrow-left'></i>","<i class='fa fa-arrow-right'></i>"],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      }
    },
    nav: true
  }
  submit(){

    if (this.registerationForm.invalid) {
      this.registerationForm.get('email').markAsTouched();
      this.registerationForm.get('name').markAsTouched();
      this.registerationForm.get('phone_number').markAsTouched();
      return;
      }
  const payload = {
    name: this.registerationForm.value.name,
    email: this.registerationForm.value.email,
    phone_number: this.registerationForm.value.phone_number,
    type: this.registerationForm.value.type
    
  }

  this.httpservice.formData(payload)
  .subscribe((res) => {
    console.log(res);
  });
  this.showSuccess();
  this.registerationForm.reset();

}

  get form() { return this.registerationForm.controls; }

  showSuccess() {
    this.messageService.add({severity:'success', summary: 'Request Submitted', detail:'Our team will get back to you soon.'});
  
  }
  
}
