import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-it-solutions',
  templateUrl: './it-solutions.component.html',
  styleUrls: ['./it-solutions.component.css']
})
export class ITSolutionsComponent implements OnInit {

  constructor(private route: Router) { }

  ngOnInit(): void {
  }
  changeRouteTo(arr){
    this.route.navigate(arr);
  }
}
