<section class="banner">
  <img src="assets/img/courses/banner.jpeg" class="img-fluid" alt="" />
</section>

<section class="content_section mb-5">
  <div class="container">
    <div class="row mt-5 mb-5">
      <div class="col-md-6">
        <strong
          >Are you Looking for the Best Institute for Online Digital Marketing
          Training Course in Chandigarh?</strong
        >
        <p>
          Digimiles offers Digital Marketing training classes with live projects
          by the expert trainer in Tricity. Our Digital marketing training
          program in Mohali is specially designed for Students, Under-Graduates,
          Graduates, Working Professionals, and Freelancers. We provide end to
          end learning on Digital Marketing Domain with deeper dives for
          creating a winning career for every profile.
        </p>
        <strong class="mt-4"
          >Why To Enroll In Our Digital Marketing Training Course?</strong
        >
        <p>
          We Focus on Innovative ideas, High-quality Training, Smart Classes,
          100% job assistance, and Opening the doors of opportunities for you.
          We place you on live projects so that you get the real-world flavor.
        </p>
      </div>
      <div class="col-md-6">
        <img src="assets/img/courses/img1.jpeg" class="img-fluid" alt="" />
      </div>
    </div>
  </div>
</section>

<section class="courses_section service_section">
  <div class="container">
    <h1 class="text-center mb-5">Courses and Trainings</h1>
    <div class="row mt-5">
      <div class="col-md-4 wow fadeInUp">
        <p-card [style]="{ 'margin-bottom': '2em' }">
          <img
            class="services-icon"
            src="assets/img/courses/icon4.png"
            alt=""
          />

          <h4>Digital Marketing</h4>
          <p>
            Become familiar with core marketing concepts.Discover the benefits
            and scope of digital marketing. Understand the components of an
            effective marketing strategy.
          </p>
        </p-card>
      </div>

      <div class="col-md-4 wow fadeInUp">
        <p-card [style]="{ 'margin-bottom': '2em' }">
          <img class="services-icon" src="assets/img/home/seo.png" alt="" />

          <h4>Search Engine Optimization</h4>
          <p>
            The Advanced Search Engine Optimization (SEO) course is designed to
            transform you into an industry-ready SEO professional from day one.
          </p>
        </p-card>
      </div>
      <div class="col-md-4 wow fadeInUp">
        <p-card [style]="{ 'margin-bottom': '2em' }">
          <img
            class="services-icon"
            src="assets/img/home/digital-marketing.png"
            alt=""
          />
          <h4>Social Media Marketing</h4>
          <p>
            This course will make you an expert social media, facebook
            marketing, youtube marketing, email and mobile marketing, and
            marketing automation.
          </p>
        </p-card>
      </div>
      <div class="col-md-4 wow fadeInUp">
        <p-card [style]="{ 'margin-bottom': '2em' }">
          <img
            class="services-icon"
            src="assets/img/home/it-solutions.png"
            alt=""
          />
          <h4>Wordpress</h4>
          <p>
            Wordpress is the most popular and the modern blogging platform in
            the world. Wordpress is used as a Content Management System in
            almost all companies as it is easy to install and use.
          </p>
        </p-card>
      </div>

      <div class="col-md-4 wow fadeInUp">
        <p-card [style]="{ 'margin-bottom': '2em' }">
          <img
            class="services-icon"
            src="assets/img/courses/content.png"
            alt=""
          />
          <h4>Content Creation</h4>
          <p>
            It is a broad field that includes everything from writing blog posts
            or articles to scripting videos and podcasts and preparing content
            for specific social media platforms.
          </p>
        </p-card>
      </div>
    </div>
  </div>
</section>
