
  <section class="wrapper">
    <div class="container">
      <div class="row mt-50">
        <div class="col-12">
          <ul class="services">
            <li [routerLinkActive]="['active']">
              <a (click)="changeRouteTo(['/', 'services', 'web-designing'])"
                >Web Designing</a
              >
            </li>
            <li [routerLinkActive]="['active']">
              <a (click)="changeRouteTo(['/', 'services', 'it-solutions'])"
                >IT Solutions</a
              >
            </li>
            <li [routerLinkActive]="['active']">
              <a
                (click)="
                  changeRouteTo(['/', 'services', 'social-media-marketing'])
                "
                >Social Media Marketing</a
              >
            </li>
            <li [routerLinkActive]="['active']">
              <a (click)="changeRouteTo(['/', 'services', 'app-development'])"
                >App Development</a
              >
            </li>
            <li [routerLinkActive]="['active']">
              <a (click)="changeRouteTo(['/', 'services', 'creative-design'])"
                >Creative Design</a
              >
            </li>
            <li [routerLinkActive]="['active']">
              <a (click)="changeRouteTo(['/', 'services', 'brand-management'])"
                >Brand Management</a
              >
            </li>
          </ul>
        </div>
      </div>
      <div class="row showcase-section">
        <div class="col-md-6">
                 <img class="img-responsive" src="assets/img//services/app.jpg" alt="">
        </div>

        <div class="col-md-6">
                              <h3 class="mt-0">App Development</h3>
                 <p>At Digimiles, we specialize in delivering custom web application development solutions that can automate your business processes and provide a superior ROI to your technology investments.</p>
                 <p>At Digimiles, we believe digital technologies will drastically change the way businesses function in the future. Our offerings are focused towards equipping organizations offer exceptional customer experience through our technology and usability services. With capabilities across platforms, our app development services can availed to build both customer and enterprise apps.</p>
     
</div>

     </div>
     
     <div class="row">
         <div class="col-md-6">
             <div>
                 <h3 class="mt-0"> Concept Analysis</h3>
                 <p>We understand that mobile development can be a difficult task, especially since interaction varies greatly from the traditional desktop experience. Our team will develop a strategy to tackle the development of your Android application and create the best possible user experience.</p>
                 <p>It is not enough to just build a brilliant mobile app. If your audience isn’t finding it, it doesn't really exist. Our team develops a comprehensive strategy to generate an initial interest in your mobile application and improves the visibility of your application</p>
         </div>

</div>
         <div class="col-md-6">
                  <img class="img-responsive" src="assets/img/services/concept-analysis.jpg" alt="">
         </div>
      </div>
      <div class="row">
        <div class="col-12 text-center">
          <a (click)="changeRouteTo(['/', 'contact'])"
            ><button type="button" class="btn btn-secondary mt-5 my-5">
              Contact Us
            </button></a
          >
        </div>
      </div>
    </div>
  </section>

