import { Component, OnInit } from '@angular/core';
import { blogs } from '../../models/blogs';

@Component({
  selector: 'app-blogs',
  templateUrl: './blogs.component.html',
  styleUrls: ['./blogs.component.css']
})
export class BlogsComponent implements OnInit {
  blogs = blogs;
  constructor() { }

  ngOnInit(): void {
  }

}
