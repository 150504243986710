
<section class="wrapper">
    <div class="container">
      <div class="row mt-50">
        <div class="col-12">
          <ul class="services">
            <li [routerLinkActive]="['active']">
              <a (click)="changeRouteTo(['/', 'services', 'web-designing'])"
                >Web Designing</a
              >
            </li>
            <li [routerLinkActive]="['active']">
              <a (click)="changeRouteTo(['/', 'services', 'it-solutions'])"
                >IT Solutions</a
              >
            </li>
            <li [routerLinkActive]="['active']">
              <a
                (click)="
                  changeRouteTo(['/', 'services', 'social-media-marketing'])
                "
                >Social Media Marketing</a
              >
            </li>
            <li [routerLinkActive]="['active']">
              <a (click)="changeRouteTo(['/', 'services', 'app-development'])"
                >App Development</a
              >
            </li>
            <li [routerLinkActive]="['active']">
              <a (click)="changeRouteTo(['/', 'services', 'creative-design'])"
                >Creative Design</a
              >
            </li>
            <li [routerLinkActive]="['active']">
              <a (click)="changeRouteTo(['/', 'services', 'brand-management'])"
                >Brand Management</a
              >
            </li>
          </ul>
        </div>
      </div>
      <div class="row showcase-section">
        <div class="col-md-6">
          <img
            class="img-responsive"
            src="assets/img//services/creative1.jpg"
            alt=""
          />
        </div>
  
        <div class="col-md-6">
            <h3>Creative Design</h3>
            <p>Just like fashion and gadgets, website trends are ever-changing. At Online Innovations, our design team spends a huge amount of time on the internet researching and identifying what is appealing to and working for the larger audiences.Our multimedia designers have expert knowledge in Adobe graphic, video and multimedia applications.</p>
            <p>By merging experience, expertise, graphics, animations, audio and video, we can produce multimedia elements that bring life to your brand, products and services; enabling you to give prospects and in-depth look at your company or brand.</p>
        </div>
      </div>
  
      <div class="row">
        <div class="col-md-6">
          <div>
            <h3>Revolutionary Design</h3>
            <p>Our Graphic Designer empower you to design like never before. Liberate yourself from the constraints of complicated software and create beautiful graphic designs with us. </p>
            <p>The innovative Graphic Designer makes it simple to design however you want. Whether you plan on underlining words for emphasis, adding vintage ribbons to create a classic look or including a graphic of foxes riding a 2-seater bicycle, we offer fun and functional design elements that are simple to add. It's leading the way in creating a world where graphic design is quick, easy, and accessible to everyone.</p>
          </div>
        </div>
        <div class="col-md-6">
          <img
            class="img-responsive"
            src="assets/img//services/creative2.jpg"
            alt=""
          />
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-center">
          <a (click)="changeRouteTo(['/', 'contact'])"
            ><button type="button" class="btn btn-secondary mt-5 my-5">
              Contact Us
            </button></a
          >
        </div>
      </div>
    </div>
  </section>
  