<div id="wrapper">
  <section id="content">
    <section class="section-padding">
      <div class="container">
        <div class="row showcase-section">
          <div class="col-md-6">
            <img src="assets/img/About-section.png" alt="showcase image" />
          </div>
          <div class="col-md-6">
            <div class="about-text text-justify">
              <h3>Who we are</h3>
              <p>
                DIGIMILES was the need of an hour and demand of our present
                clients, it’s a digital arm of Miles Services which has served
                more then 500+ clients from inception in 2012. The main focus is
                to provide IT solutions and digital marketing solutions to our
                clients.
              </p>
              <p>
                Today DIGIMILES offers a full range of web design, app
                development and content marketing services, including building
                full-featured ecommerce websites and web startups with custom
                functionality.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="container">
      <div class="about">
        <div class="row">
          <div class="col-md-4">
            <!-- Heading and para -->
            <div class="block-heading-two">
              <h3><span>Our Mission</span></h3>
            </div>
            <p class="text-justify">
              We see our mission in providing innovative and effective
              integrated brand marketing and public relations solutions
              partnering ourselves in clients growth strategies their businesses
              and realizing their marketing goals. We believe in effective
              marketing that has real impact on the growth of website and social
              media traffic vis a vis increase in sales.
            </p>
          </div>
          <div class="col-md-4">
            <div class="block-heading-two">
              <h3><span>Our Solution</span></h3>
            </div>
            <!-- Accordion starts -->
            <div class="panel-group" id="accordion-alt3">
              <!-- Panel. Use "panel-XXX" class for different colors. Replace "XXX" with color. -->
              <div class="panel">
                <!-- Panel heading -->
                <div class="panel-heading">
                  <h4 class="panel-title">
                    <a
                      data-toggle="collapse"
                      data-parent="#accordion-alt3"
                      href="#collapseOne-alt3"
                    >
                      <i class="fa fa-angle-right"></i> We create content for
                      digital
                    </a>
                  </h4>
                </div>
                <div id="collapseOne-alt3" class="panel-collapse collapse">
                  <!-- Panel body -->
                  <div class="panel-body">
                    The client’s needs and demand made us enter into the digital
                    business; in a very short span of time we are able to cater
                    all digital needs of our clients. 80% of the content these
                    days is in the form of videos. We are making brand videos
                    for social-media, tv, cinema and OTT platforms.
                  </div>
                </div>
              </div>
              <div class="panel">
                <div class="panel-heading">
                  <h4 class="panel-title">
                    <a
                      data-toggle="collapse"
                      data-parent="#accordion-alt3"
                      href="#collapseTwo-alt3"
                    >
                      <i class="fa fa-angle-right"></i> Crafted for Business and
                      creative ads
                    </a>
                  </h4>
                </div>
                <div id="collapseTwo-alt3" class="panel-collapse collapse">
                  <div class="panel-body">
                    We blend insights and strategy to create digital products
                    for forward-thinking organisations. Making high and creative
                    ads which gives an edge in brand building. Innovative ideas
                    and strategic campaigns is what we drive at DigiMiles.
                  </div>
                </div>
              </div>
              <div class="panel">
                <div class="panel-heading">
                  <h4 class="panel-title">
                    <a
                      data-toggle="collapse"
                      data-parent="#accordion-alt3"
                      href="#collapseThree-alt3"
                    >
                      <i class="fa fa-angle-right"></i> We partner ourselves for
                      client growth
                    </a>
                  </h4>
                </div>
                <div id="collapseThree-alt3" class="panel-collapse collapse">
                  <div class="panel-body">
                    Growth can be in sales/brand-image/brand-perception, can be
                    everywhere. The Vision is clear giving the Best ROI to our
                    Clients.
                  </div>
                </div>
              </div>
            </div>
            <!-- Accordion ends -->
          </div>

          <div class="col-md-4">
            <div class="block-heading-two">
              <h3><span>Our Expertise</span></h3>
            </div>
            <h6>Hard Work</h6>
            <div class="progress pb-sm">
              <!-- White color (progress-bar-white) -->
              <div
                class="progress-bar progress-bar-red"
                role="progressbar"
                aria-valuenow="40"
                aria-valuemin="0"
                aria-valuemax="100"
                style="width: 95%"
              >
                <span class="sr-only">40% Complete (success)</span>
              </div>
            </div>
            <h6>Innovation</h6>
            <div class="progress pb-sm">
              <div
                class="progress-bar progress-bar-green"
                role="progressbar"
                aria-valuenow="60"
                aria-valuemin="0"
                aria-valuemax="100"
                style="width: 85%"
              >
                <span class="sr-only">40% Complete (success)</span>
              </div>
            </div>
            <h6>Team Work</h6>
            <div class="progress pb-sm">
              <div
                class="progress-bar progress-bar-lblue"
                role="progressbar"
                aria-valuenow="80"
                aria-valuemin="0"
                aria-valuemax="100"
                style="width: 90%"
              >
                <span class="sr-only">40% Complete (success)</span>
              </div>
            </div>
            <h6>Creativity</h6>
            <div class="progress pb-sm">
              <div
                class="progress-bar progress-bar-yellow"
                role="progressbar"
                aria-valuenow="30"
                aria-valuemin="0"
                aria-valuemax="100"
                style="width: 97%"
              >
                <span class="sr-only">40% Complete (success)</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <div class="block-heading-two">
            <h3><span>Content is the King</span></h3>
          </div>
          <p class="text-justify">
            The content marketing team at Digimiles was created for the sole
            purpose of keeping a brand relevant, engaging, and accessible
            online. The most effective way to convey high-quality knowledge
            about the products and services is through content. It analyses what
            the customer really wants to know by understanding the user's mind.
            This enables them to produce accurate content and feed it with the
            correct data. Customers can also get answers to their questions by
            going to the website.
          </p>
        </div>

        <div class="col-md-6">
          <div class="block-heading-two">
            <h3><span> About Team</span></h3>
          </div>
          <p class="text-justify">
            Enable independent, medium, and large companies in establishing a
            digital footprint. Our talented team work tirelessly to keep up with
            evolving customer preferences and patterns. There is the team of
            timely and organized individuals who manage smooth, effective &
            productive overflow. We are optimistic that, with our passion,
            experience in brainstorming strategies, and proposal of a customized
            digital marketing campaign that will directly address customer
            needs.
          </p>
        </div>
      </div>
    </div>
  </section>

  <!-- Team-section -->

  <!-- <section class="team-section">
            <div class="auto-container">
              <div class="sec-title p-40 text-center">
                <h2>Meet the expert team<span class="dot">.</span></h2>
              </div>
            </div>
            <div class="carousel-box">
              <div class="team-carousel owl-theme owl-carousel">
                                <div class="team-block">
                  <div class="inner-box">
                    <div class="image-box">
                      <img src="assets/img/team/7.jpg" alt=""
                      />
                    </div>
                    <div class="lower-box">
                      <h5>Jasneet Bedi</h5>
                      <div class="designation">CEO</div>
                    </div>
                  </div>
                </div>
    
                               <div class="team-block">
                  <div class="inner-box">
                    <div class="image-box">
                     <img src="assets/img/team/8.jpg" alt=""
                      />
                   
                    </div>
                    <div class="lower-box">
                      <h5>Jagbir Singh</h5>
                      <div class="designation">Admin</div>
                    </div>
                  </div>
                </div>
    
                                <div class="team-block">
                  <div class="inner-box">
                    <div class="image-box">
                      <img src="assets/img/team/1.jpg" alt=""
                      />
                    
                    </div>
                    <div class="lower-box">
                      <h5>Surjeet Singh</h5>
                      <div class="designation">SEO Executive</div>
                    </div>
                  </div>
                </div>
    
                                <div class="team-block">
                  <div class="inner-box">
                    <div class="image-box">
                   <img src="assets/img/team/6.jpg" alt=""
                      />
                    </div>
                    <div class="lower-box">
                      <h5>Kanwalpreet Kaur</h5>
                      <div class="designation">Client Servicing</div>
                    </div>
                  </div>
                </div>
    
                                <div class="team-block">
                  <div class="inner-box">
                    <div class="image-box">
                      <img src="assets/img/team/2.jpg" alt=""
                      />
                   
                    </div>
                    <div class="lower-box">
                      <h5>Yashpreet</h5>
                      <div class="designation">Client Servicing</div>
                    </div>
                  </div>
                </div>
                                <div class="team-block">
                  <div class="inner-box">
                    <div class="image-box">
                      <img src="assets/img/team/3.jpg" alt=""
                      />
                    </div>
                    <div class="lower-box">
                      <h5>Devanshu Sharma</h5>
                      <div class="designation">Creative/Video Editor</div>
                    </div>
                  </div>
                </div>
    
                                <div class="team-block">
                  <div class="inner-box">
                    <div class="image-box">
                    <img src="assets/img/team/4.jpg" alt=""
                      />
                   
                    </div>
                    <div class="lower-box">
                      <h5>Rajat kandhari</h5>
                      <div class="designation">Agency Partner</div>
                    </div>
                  </div>
                </div>
    
                                <div class="team-block">
                  <div class="inner-box">
                    <div class="image-box">
                      <img src="assets/img/team/5.jpg" alt=""
                      />
                
                    </div>
                    <div class="lower-box">
                      <h5>Parkash</h5>
                      <div class="designation">Office Boy</div>
                    </div>
                  </div>
                </div>
    
                                <div class="team-block">
                  <div class="inner-box">
                    <div class="image-box">
                     <img src="assets/img/team/9.jpg" alt=""
                      />
                  
                    </div>
                    <div class="lower-box">
                      <h5>Gurprasad Singh</h5>
                      <div class="designation">Director/Video Graphics</div>
                    </div>
                  </div>
                </div>
    
                                                <div class="team-block">
                          <div class="inner-box">
                            <div class="image-box">
                             <img src="assets/img/team/10.jpg" alt=""
                              />
                          
                            </div>
                            <div class="lower-box">
                              <h5>DIVLEENA AHLUWALIA</h5>
                              <div class="designation">CTO</div>
                            </div>
                          </div>
                        </div>      
              </div>  
           
            </div>
          </section> -->
  <a href="#" class="scrollup"><i class="fa fa-angle-up active"></i></a>
</div>
