<section class="wrapper">
    <div class="container">
      <div class="row mt-50">
        <div class="col-12">
          <ul class="services">
            <li [routerLinkActive]="['active']">
              <a (click)="changeRouteTo(['/', 'services', 'web-designing'])"
                >Web Designing</a
              >
            </li>
            <li [routerLinkActive]="['active']">
              <a (click)="changeRouteTo(['/', 'services', 'it-solutions'])"
                >IT Solutions</a
              >
            </li>
            <li [routerLinkActive]="['active']">
              <a
                (click)="
                  changeRouteTo(['/', 'services', 'social-media-marketing'])
                "
                >Social Media Marketing</a
              >
            </li>
            <li [routerLinkActive]="['active']">
              <a (click)="changeRouteTo(['/', 'services', 'app-development'])"
                >App Development</a
              >
            </li>
            <li [routerLinkActive]="['active']">
              <a (click)="changeRouteTo(['/', 'services', 'creative-design'])"
                >Creative Design</a
              >
            </li>
            <li [routerLinkActive]="['active']">
              <a (click)="changeRouteTo(['/', 'services', 'brand-management'])"
                >Brand Management</a
              >
            </li>
          </ul>
        </div>
      </div>
      <div class="row showcase-section">
        <div class="col-md-6">
          <img
            class="img-responsive"
            src="assets/img//services/IT.jpg"
            alt=""
          />
        </div>
  
        <div class="col-md-6">
            <h3>IT Solutions</h3>
            <p>We provide you with only the best and most economic IT solutions for your business needs. We can provide you with a plan and recommendations, as well as implementation of an entire IT solution.</p>
            <p>Our custom IT services help clients build and integrate sophisticated business applications and/or websites with client server and legacy systems. We create and deploy robust, scalable and extensible architectures for use in a wide range of industries.</p>
        </div>
      </div>
  
      <div class="row">
        <div class="col-md-6">
          <div>
            <h3> Our Approach</h3>
            <p>Digimiles boasts of highly skilled software development professionals who deliver fully customized and business centric software that are both high performance and tight in security. Our software developers provide you with highly innovative and value driven software development services. Over the years, we have gained for ourselves the expertise that enables us to deliver cutting edge software solutions that suit your business requirements giving you a competitive edge.</p>
          </div>
        </div>
        <div class="col-md-6">
          <img
            class="img-responsive"
            src="assets/img//services/IT2.jpg"
            alt=""
          />
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-center">
          <a (click)="changeRouteTo(['/', 'contact'])"
            ><button type="button" class="btn btn-secondary mt-5 my-5">
              Contact Us
            </button></a
          >
        </div>
      </div>
    </div>
  </section>
  