import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { MessageService } from 'primeng/api';
import { QueryService } from 'src/app/query.service';
import { WOW } from 'wowjs/dist/wow.min';
import { DomSanitizer } from '@angular/platform-browser';

// import { Title, Meta } from '@angular/platform-browser';

declare var $: any;
interface City {
  name: string,
  code: string
}
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  title = 'Digimiles | Best Digital Marketing company in Mohali';
  userform: FormGroup;   
  queries: City[];
  submitted = false;
  display: boolean = false;
  error: {};
  responsiveOptions;
  products = [
    {
      link: "https://www.youtube.com/embed/yAoLSRbwxL8"  
    },
    {
      link: "https://www.youtube.com/embed/yAoLSRbwxL8"  
    },
    {
      link: "https://www.youtube.com/embed/yAoLSRbwxL8"  
    }
]

  constructor(private fb: FormBuilder,private queryservice: QueryService, private sanitizer: DomSanitizer, private router: Router, private messageService: MessageService ) {
    this.queries = [
      { name: 'Digital Marketing', code: 'DM' },
      { name: 'Website Development', code: 'WD' },
      { name: 'App Development', code: 'AD' },
    ];
    this.responsiveOptions = [
      {
          breakpoint: '1024px',
          numVisible: 3,
          numScroll: 3
      },
      {
          breakpoint: '768px',
          numVisible: 2,
          numScroll: 2
      },
      {
          breakpoint: '560px',
          numVisible: 1,
          numScroll: 1
      }
  ];
  }

  ngAfterViewInit(){
    new WOW().init();
  }

  ngOnInit() {
    this.userform = this.fb.group({
        'name': new FormControl('', Validators.required),
        'email': new FormControl('', Validators.required),
        'number': new FormControl('', [Validators.required, Validators.minLength(10)]),
        'query' : new FormControl('',Validators.required),
        'message': new FormControl('',Validators.required)
    });
}

// selectedOption() {
//   this.safeUrl = this.sanitizer.bypassSecurityTrustHtml('this.products');
// }

onSubmit(value: string) {
  this.submitted = true;
  const data = {
    name: this.userform.value.name,
    email: this.userform.value.email,
    number: this.userform.value.number,
    query: this.userform.value.query.name,
    message: this.userform.value.message,
  };
  this.showSuccess();                                              
  this.display = false;
  this.userform.reset();
   this.queryservice.contactForm(data).subscribe(
    data => data,
    error => this.error = error
  );
}
  ngDoCheck(){
    $('.flexslider').flexslider();
}

showSuccess() {
  this.messageService.add({severity:'success', summary: 'Email Confirmation', detail:'Thanks your email has been submitted.'});
}

showDialog() {
  this.display = true;
}

customOptions: OwlOptions = {
  loop: true,
  mouseDrag: false,
  pullDrag: false,
  dots: false,
  margin: 20,
  autoplay: true,
  autoplayHoverPause: true,
  navSpeed: 700,
  navText : ["<i class='fa fa-arrow-left'></i>","<i class='fa fa-arrow-right'></i>"],
  responsive: {
    0: {
      items: 1
    },
    400: {
      items: 2
    },
    740: {
      items: 3
    },
    940: {
      items: 3
    }
  },
  nav: true
}
}
