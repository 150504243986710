import { BrowserModule,Title, Meta } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ReactiveFormsModule } from '@angular/forms';
import {ToastModule} from 'primeng/toast';
import {CardModule} from 'primeng/card';
import {DividerModule} from 'primeng/divider';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { HomeComponent } from './pages/home/home.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { ServicesComponent } from './pages/services/services.component';
import { PortfolioComponent } from './pages/portfolio/portfolio.component';
import { ContactComponent } from './pages/contact/contact.component';
import { WebDesigningComponent } from './service-pages/web-designing/web-designing.component';
import { ITSolutionsComponent } from './service-pages/it-solutions/it-solutions.component';
import { SocialMediaMarketingComponent } from './service-pages/social-media-marketing/social-media-marketing.component';
import { AppDevelopmentComponent } from './service-pages/app-development/app-development.component';
import { CreativeDesignComponent } from './service-pages/creative-design/creative-design.component';
import { BrandManagementComponent } from './service-pages/brand-management/brand-management.component';
import {TabViewModule} from 'primeng/tabview';
import { PaymentComponent } from './pages/payment/payment.component';
import {ButtonModule} from 'primeng/button';
import {DialogModule} from 'primeng/dialog';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {InputTextModule} from 'primeng/inputtext';
import {DropdownModule} from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { DigitalMarketingComponent } from './pages/digital-marketing/digital-marketing.component';
import { WebDevelopmentComponent } from './pages/web-development/web-development.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { TermsComponent } from './pages/terms/terms.component';
import { RefundPolicyComponent } from './pages/refund-policy/refund-policy.component';
import { CoursesComponent } from './pages/courses/courses.component';
import { CommonModule } from '@angular/common';
import { BlogsComponent } from './pages/blogs/blogs.component';
import { BlogDetailsComponent } from './pages/blog-details/blog-details.component';
import { ItSolutionsComponent } from './pages/it-solutions/it-solutions.component';
import { LandingPageComponent } from './pages/landing-page/landing-page.component';


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    AboutUsComponent,
    ServicesComponent,
    PortfolioComponent,
    ContactComponent,
    WebDesigningComponent,
    ITSolutionsComponent,
    SocialMediaMarketingComponent,
    AppDevelopmentComponent,
    CreativeDesignComponent,
    BrandManagementComponent,
    PaymentComponent,
    DigitalMarketingComponent,
    WebDevelopmentComponent,
    PrivacyPolicyComponent,
    TermsComponent,
    RefundPolicyComponent,
    CoursesComponent,
    BlogsComponent,
    BlogDetailsComponent,
    ItSolutionsComponent,
    LandingPageComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CarouselModule ,
    TabViewModule,
    ReactiveFormsModule,
    ButtonModule,
    DialogModule,
    BrowserAnimationsModule,
    InputTextModule,
    DropdownModule,
    FormsModule,
    HttpClientModule,
    ToastModule,
    CardModule,
    DividerModule,
    CommonModule
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  providers: [Meta,Title],
  bootstrap: [AppComponent]
})
export class AppModule {
 
 }
