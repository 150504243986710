<div id="wrapper">
  <section >
    <div class="container content">
      <!-- Service Blcoks -->

      <div class="row">
        <div class="col-md-12">
          <div class="about-content">
            <h3>Our Best <span class="color">Services</span></h3>
            <p class="text-justify">
              Many can drive a car but only few can repair them fully. Only few
              know the dynamics behind it only few have the tools to do the
              same. We at DIGIMILES are equipped with all the latest techonlogy,
              softwares, website tools and modules and our consitent learning on
              projects and off projects make us stand apart in terms of latest
              applications in digital world. Our team is well aware of the
              current trends of digital world and they adapt themselves and
              deliver their best with changing digital technologies and
              environment.
            </p>
            <p class="text-justify">
              There are thousands of agencies in digital platform who can or may
              be much better than us. But the kind of experience and journey we
              provide to our client makes us a class apart from others. No
              Agency or firm can grow on stand alone basis we partner our selves
              in the growth of client's Business vis a vis growing our own.
            </p>
            <p class="text-justify">
              We are in the process of providing more top level communication
              and constant availability. Our content developers write great
              marketing copy that is both insightful and persuasive, all while
              driving the company's goals. There is no such thing as a
              performance formula. Every company, regardless of scale, is
              special and demands round-the-clock attention from industry
              experts to provide content that connects them to their target
              audience & that's where we come in. We've worked with some of the
              most well-known brands online through a wide range of industries.
            </p>
            <p class="text-justify">
              With the assistance of Digimiles, you will stay ahead of the
              digital curve and make a difference in your niche market. Our
              digital marketing agency is here to help the brand succeed online,
              regardless of the business or target audiences. Using strategies
              like ad campaigns, social influencing, and top-tier page
              optimization, we will increase organic traffic.
            </p>
          </div>
        </div>
      </div>
      <div class="row service-v1 margin-bottom-40">
        <div class="col-md-4 md-margin-bottom-40">
          <img
            class="img-responsive"
            src="assets/img/services/service1.jpg"
            alt=""
          />
          <a routerLink="web-designing"><h3>Web Designing</h3></a>
          <p class="text-justify">
            We are a dedicated web design company and our focus is designing
            your website to suit your individual requirements. The look and feel
            and user experiance of a website is one of the most important
            elements.
          </p>
        </div>
        <div class="col-md-4">
          <img
            class="img-responsive"
            src="assets/img/services/service2.jpg"
            alt=""
          />
          <a routerLink="it-solutions"><h3>IT Solutions</h3></a>
          <p class="text-justify">
            We provide you with only the best and most economic IT solutions for
            your business needs. We can provide you with a plan and
            recommendations, as well as implementation of an entire IT solution.
          </p>
        </div>
        <div class="col-md-4 md-margin-bottom-40">
          <img
            class="img-responsive"
            src="assets/img/services/service3.jpg"
            alt=""
          />
          <a routerLink="social-media-marketing"
            ><h3>Social Media Marketing</h3></a
          >
          <p class="text-justify">
            As social media becomes the one of most influential marketing
            channels today, businesses realize the need for establishing
            themelves strongly on the various channels.
          </p>
        </div>
      </div>

      <div class="row service-v1 pt-5 margin-bottom-40 mb-5">
        <div class="col-md-4 md-margin-bottom-40">
          <img
            class="img-responsive"
            src="assets/img/services/service4.jpg"
            alt=""
          />
          <a routerLink="app-development"><h3>Mobile App Development</h3></a>
          <p class="text-justify">
            Evolving business challenges require technology solutions that are
            adaptive and scalable as your business grows. At Digimiles, we
            specialize in delivering custom web application development
            solutions that can automate your business processes and provide a
            superior ROI to your technology investments.
          </p>
        </div>
        <div class="col-md-4">
          <img
            class="img-responsive"
            src="assets/img/services/service5.jpg"
            alt=""
          />
          <a routerLink="creative-design"><h3>Creative Design</h3></a>
          <p class="text-justify">
            Just like fashion and gadgets, website trends are ever-changing. At
            Online Innovations, our design team spends a huge amount of time on
            the internet researching and identifying what is appealing to and
            working for the larger audiences.Our multimedia designers have
            expert knowledge in Adobe graphic, video and multimedia
            applications.
          </p>
        </div>
        <div class="col-md-4 md-margin-bottom-40">
          <img
            class="img-responsive"
            src="assets/img/services/service6.jpg"
            alt=""
          />
          <a routerLink="brand-management"><h3>Brand Management</h3></a>
          <p class="text-justify">
            Branding involves more than just a name and a creative logo, but a
            strategy behind your identity and the unique services your business
            provides. First impressions can often make or break a business, and
            that's why in this digitally growing world brands should be developed
            with a digital-first mentality to perform first and foremost in the
            modern digital landscape.
          </p>
        </div>
      </div>
      <!-- End Service Blcoks -->
    </div>
  </section>
</div>
