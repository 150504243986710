<div class="container">
    <div class="row mt-5">
      <div class="col-md-8 card offset-md-2 p-5 mt-5" [hidden]="!(!isPaymentDone && !isError)">
        <h1 class="mb-5 mt-3">Payment Form</h1>
        <form [formGroup]="registerForm">
          <div class="form-group">
            <label for="exampleInputPassword1">Currency</label>
            <select class="custom-select  mb-3" formControlName="currency_code" (change)="changeCurrency()">
              <option disabled [ngValue]="null">Select Currency</option>
              <option *ngFor="let items of currencies" [ngValue]="items">
                {{items}}</option>
            </select>
          </div>
          <div class="form-group">
            <label for="exampleInputEmail1">Amount Description</label>
            <input type="email" class="form-control" id="exampleInputEmail1" formControlName="description" aria-describedby="emailHelp">
          </div>
          <div class="form-group">
            <label for="exampleInputPassword1">Amount</label>
            <input type="number" class="form-control mb-3" min="0" onkeypress="return event.charCode != 45" id="exampleInputPassword1" formControlName="amount">
          </div>
  
        </form>
        <div #paypal class="mt-4 mb-5" [hidden]="registerForm.invalid" ></div>
      </div>
  
      <div class="col-md-8 card offset-md-2 p-5 mt-5 text-center" *ngIf="isPaymentDone">
  
        <!-- <span class="card__success"><i class="ion-checkmark"></i></span> -->
        <p class="mt-5"><img src="https://rishtadepot.com/wp-content/uploads/2020/09/ClipartKey_1304453.png" alt="check" class="width"> </p>
  
        <h1 class="card__msg mt-3">Payment Complete</h1>
        <h2 class="card__submsg mb-5 pb-5">Thank you for your transfer</h2>
  
      </div>
  
      <div class="col-md-8 card offset-md-2 p-5 mt-5 text-center" *ngIf="isError">
  
        <!-- <span class="card__success"><i class="ion-checkmark"></i></span> -->
        <p class="mt-5"><img src="https://rishtadepot.com/wp-content/uploads/2020/09/error-icon-25243.png" alt="error" class="width"> </p>
  
        <h1 class="card__msg mt-3">Payment Not Completed</h1>
        <h2 class="card__submsg mb-5 pb-5">Please refresh the page and try it again </h2>
  
      </div>
  
    </div>
  </div>
  
  