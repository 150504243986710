<div class="wrapper">
  <section id="content">
    <div class="container">
      <div class="row pb-5">
        <div class="col-md-12">
          <div class="about-logo">
            <h3 class="text-center">We're excited to talk to you!</h3>
          </div>
        </div>
        <div class="col-md-6">
          <h3>India Address</h3>
          <p>
            <strong>Head Office:</strong> 6th floor, 602, D-107, Phase 7, Industrial Area,
            Sector 73, Pincode: 160054
          </p>
          <p>
            <strong>Registered Office:</strong> #709, Phase 3B-1, Mohali, 160059
          </p>
          <p>
            <strong>Amritsar:</strong> 24-FF, Nehru Shopping Complex, Lawrence
            Road, Amritsar
          </p>
        </div>
        <div class="col-md-6">
          <h3>India Contact Number</h3>
          <p><strong>Landline:</strong> 0172-400-5135</p>
          <p><strong>Mobile:</strong> +91 9041020709</p>
          <a href="mailto:info@digimiles.net">
            <p class="text-black">
              <strong>Email:</strong> info&#64;digimiles.net
            </p></a
          >
        </div>

        <div class="col-md-6">
          <h3>North America Address</h3>
          <p>
            <strong>Bothell:</strong> 19928 Bothell Everett Hwy, Bothell, WA
            98012
          </p>
        </div>
        <div class="col-md-6">
          <h3>North America Contact Number</h3>
          <p><strong>Mobile:</strong> +1 (415) 767-1699</p>
          <a href="mailto:info@digimiles.net">
            <p class="text-black">
              <strong>Email:</strong> info&#64;digimiles.net
            </p></a
          >
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13720.945953151506!2d76.6783085313172!3d30.71175142909876!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390fee8865e41ea9%3A0x4a4c745a65aae6ca!2sDIGIMILES!5e0!3m2!1sen!2sin!4v1653465710262!5m2!1sen!2sin"
            width="100%"
            height="600"
            frameborder="0"
            style="border: 0"
            allowfullscreen=""
            aria-hidden="false"
            tabindex="0"
          ></iframe>
          
        </div>

      </div>
    </div>
  </section>
</div>
