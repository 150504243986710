import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import {Blogs, blogs } from '../../models/blogs';

@Component({
  selector: 'app-blog-details',
  templateUrl: './blog-details.component.html',
  styleUrls: ['./blog-details.component.css']
})
export class BlogDetailsComponent implements OnInit {
  blog: Blogs;
  constructor(
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    const routeParams = this.route.snapshot.paramMap;
    const blogIdFromRoute = Number(routeParams.get('blogId'));
    this.blog = blogs.find(blog => blog.id === blogIdFromRoute);
  }

}
