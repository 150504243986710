import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-social-media-marketing',
  templateUrl: './social-media-marketing.component.html',
  styleUrls: ['./social-media-marketing.component.css']
})
export class SocialMediaMarketingComponent implements OnInit {

  constructor( private route: Router) { }

  ngOnInit(): void {
  }
  changeRouteTo(arr){
    this.route.navigate(arr);
  }
}
