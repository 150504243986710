<section>
  <div class="container">
      <div *ngIf="blog">
        <div class="mb-5 mt-5">
          <img src="{{ blog.image }}" alt="{{ blog.alt_text }}" class="img-fluid" />   
        <h2>{{ blog.title }}</h2>
        <div class="col-12">
          <p [innerHtml]="blog.description">
          </p>

        </div>
      </div>
    </div>
  </div>
</section>
