import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'app-it-solutions',
  templateUrl: './it-solutions.component.html',
  styleUrls: ['./it-solutions.component.css']
})
export class ItSolutionsComponent implements OnInit {
  registerationForm:FormGroup;
  constructor(private formBuilder: FormBuilder,
    private httpservice: HttpService,
    private messageService: MessageService) { }

  ngOnInit(): void {
    this.registerationForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)    ]],
      name: ['', Validators.required],
      phone_number: ['', [Validators.required, Validators.pattern('[0-9 ]{10,15}' )]],
      type: ['Web and App Development']

    }); 
  }
  submit(){

    if (this.registerationForm.invalid) {
      this.registerationForm.get('email').markAsTouched();
      this.registerationForm.get('name').markAsTouched();
      this.registerationForm.get('phone_number').markAsTouched();
      return;
      }
  
    const payload = {
      name: this.registerationForm.value.name,
      email: this.registerationForm.value.email,
      phone_number: this.registerationForm.value.phone_number,
      type: this.registerationForm.value.type
      
    }
    
      this.httpservice.formData(payload)
        .subscribe((res) => {
          console.log(res);
        });
        this.showSuccess();
        this.registerationForm.reset();
  
  }

  get form() { return this.registerationForm.controls; }

  showSuccess() {
    this.messageService.add({severity:'success', summary: 'Request Submitted', detail:'Our team will get back to you soon.'});
  
  }
  

}
