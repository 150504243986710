<section class="banner_section">
  <div class="container-fluid p-0">
    <div class="col-md-12 p-0">
      <img
        src="assets/img/web-development-banner.jpg"
        class="img-fluid desktop"
        alt="banner"
      />
      <img src="assets/img/web-mob.jpg" alt="" class="mobile img-fluid">
      <div class="banner_content flexslider" id="main-slider">
        <ul class="slides">
          <li>
            <p>
              Need a website but don’t know how where to start. Contact us to
              give you a head start
            </p>
          </li>
          <li>
            <p>
              Trying to estimate the true costing for your website and app?
              Contact us if you don’t want to be cheated.
            </p>
          </li>

          <li>
            <p>
              So, the cost for your app comes higher than what you expected?
              Have a meeting with us to see how we can make it more feasible for
              you?
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>

<section class="form_section pb-5">
  <div class="container">
    <div class="row d-flex">
      <div class="col-md-7 order">
        <div class="content_section">
          <strong><p>If you :-</p> </strong>
          <ul>
            <li>Don’t know why you need a website</li>
            <li>Had a late delivery for a well briefed IT project</li>
            <li>
              Were charged more for a website than it actually costed due to
              lack of knowledge
            </li>
            <li>Got delivered a website that doesn’t perform</li>
            <li>Don’t know where to start and who to ask about “IT stuff”</li>
          </ul>
          <p>Then we understand you.</p>
          <p>
            We know that your vision need’s a combination of transparency,
            reliability and an honest approach that helps you turn your vision
            to a reality.
          </p>
          <p>
            Whether it is a simple website or an e-commerce store that has a
            custom functionality. We deliver the solution you need now that can
            be upgraded for the future as well.
          </p>
        </div>
      </div>
      <div class="col-md-5 " #target>
        <div class="card get_in_touch rounded box-shadow">
          <div class="card-header rounded-top">
            <h5 class="text-bold text-white m-0">
              <i class="fa fa-pencil"></i>
              GET IN TOUCH
            </h5>
            <p class="text-white mt-3 p-0 mb-0">
              Please complete the form below and we will get back to you ASAP!
            </p>
          </div>
          <form
            [formGroup]="registerationForm"
            (ngSubmit)="submit()"
            class="p-5"
          >
            <div class="form-group">
              <label for="exampleInputEmail1" class="text-bold">Name</label>
              <input
                type="text"
                class="form-control"
                formControlName="name"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="Enter Name"
              />
              <span
                class="invalid-feedback d-block"
                *ngIf="
                  form.name.touched &&
                  registerationForm.get('name').hasError('required')
                "
                >Name is required</span
              >
            </div>
            <div class="form-group">
              <label for="exampleInputPassword1" class="text-bold">Email</label>
              <input
                type="email"
                class="form-control"
                formControlName="email"
                id="exampleInputPassword1"
                placeholder="Enter email"
              />
              <span
                class="invalid-feedback d-block"
                *ngIf="
                  form.email.touched &&
                  registerationForm.get('email').hasError('required')
                "
                >Email is required</span
              >
              <span
                class="invalid-feedback d-block"
                *ngIf="
                  form.email.touched &&
                  registerationForm.get('email').hasError('pattern') &&
                  form.email.invalid
                "
                >Email must be a valid email address</span
              >
            </div>
            <div class="form-group">
              <label for="exampleInputPassword1" class="text-bold"
                >Phone Number</label
              >
              <input
                type="number"
                class="form-control"
                formControlName="phone_number"
                id="exampleInputPassword1"
                minlength="10"
                placeholder="Enter phone number "
              />
              <span
                class="invalid-feedback d-block"
                *ngIf="
                  form.phone_number.touched &&
                  registerationForm.get('phone_number').hasError('required')
                "
                >Phone number is required</span
              >
              <span
                class="invalid-feedback d-block"
                *ngIf="
                  form.phone_number.touched &&
                  form.phone_number?.errors?.pattern &&
                  form.phone_number.invalid
                "
                >Min length should be 10 digits</span
              >
            </div>
            <p-toast [style]="{ marginTop: '80px' }"></p-toast>

            <button type="submit" class="btn btn-primary mt-3 box-shadow">
              Get Quote
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="our_portfolio">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h2 class="mt-5 mb-5 pb-5 text-uppercase text-center font-weight-bold">
          Our Recent Portfolio
        </h2>
      </div>
      <div class="col-md-12">
        <p-tabView class="responsive-tab-view" [scrollable]="true">
          <p-tabPanel header="Happy Customers">
            <div class="row">
              <div class="col-md-4 p-5">
                <iframe
                  width="100%"
                  height="280"
                  src="https://www.youtube.com/embed/IiRZJRaekGM"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
              <div class="col-md-4 p-5">
                <iframe
                  width="100%"
                  height="280"
                  src="https://www.youtube.com/embed/_ZXdVv7ZzGk"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
              <div class="col-md-4 p-5">
                <iframe
                  width="100%"
                  height="280"
                  src="https://www.youtube.com/embed/lHrp7ecUu-M"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
              <div class="col-md-4 p-5">
                <iframe
                  width="100%"
                  height="280"
                  src="https://www.youtube.com/embed/qgEJEHbAXG4"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
              <div class="col-md-4 p-5">
                <iframe
                  width="100%"
                  height="280"
                  src="https://www.youtube.com/embed/y04yPKRgb6U"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
              <div class="col-md-4 p-5">
                <iframe
                  width="100%"
                  height="280"
                  src="https://www.youtube.com/embed/KpP7eOuPjLQ"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
              <div class="col-md-4 p-5">
                <iframe
                  width="100%"
                  height="280"
                  src="https://www.youtube.com/embed/EQXSL0w7eO8"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </p-tabPanel>
          <p-tabPanel header="Website Development">
            <div class="row">
              <div class="col-md-4 p-5" *ngFor="let images of webGallery">
                <div class="gallery-item">
                  <div class="gallery-thumb">
                    <a href="{{ images.link }}" target="_blank"
                      ><img
                        src="{{ images.img }}"
                        class="img-responsive"
                        alt="1st gallery Thumb"
                    /></a>
                    <div class="image-overlay"></div>
                    <a
                      href="{{ images.link }}"
                      target="_blank"
                      class="gallery-link"
                      ><i class="fa fa-link"></i
                    ></a>
                  </div>
                </div>
              </div>
            </div>
          </p-tabPanel>
          <p-tabPanel header="Awards & Accolades">
            <div class="col-md-4 p-5">
              <img
                src="assets/img/awards/9_graphics_DIGIMILES_.jpg"
                class="img-fluid"
                alt=""
              />
            </div>
            <div class="col-md-4 p-5">
              <img
                src="assets/img/awards/10_graphics_DIGIMILES_.jpg"
                class="img-fluid"
                alt=""
              />
            </div>
            <div class="col-md-4 p-5">
              <img
                src="assets/img/awards/14_graphics_DIGIMILES_.jpg"
                class="img-fluid"
                alt=""
              />
            </div>
            <div class="col-md-4 p-5">
              <img
                src="assets/img/awards/15_graphics_DIGIMILES_.jpg"
                class="img-fluid"
                alt=""
              />
            </div>
            <div class="col-md-4 p-5">
              <img
                src="assets/img/awards/16_graphics_DIGIMILES_.jpg"
                class="img-fluid"
                alt=""
              />
            </div>
            <div class="col-md-4 p-5">
              <img
                src="assets/img/awards/17_graphics_DIGIMILES_.jpg"
                class="img-fluid"
                alt=""
              />
            </div>
          </p-tabPanel>
        </p-tabView>
        <!-- <p-tabView>
                <p-tabPanel header="Happy Customers"> Content 1 </p-tabPanel>
                <p-tabPanel header="Graphic Designing"> Content 2 </p-tabPanel>
                <p-tabPanel header="Creative Videos"> Content 3 </p-tabPanel>
              </p-tabView>        -->
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 text-center">
        <button
          type="submit"
          class="btn btn-primary scroll-btn mt-3 box-shadow"
          (click)="scroll(target)"
        >
          Get Quote
        </button>
      </div>
    </div>
  </div>
</section>
