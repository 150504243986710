import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { ServicesComponent } from './pages/services/services.component';
import { PortfolioComponent } from './pages/portfolio/portfolio.component';
import { ContactComponent } from './pages/contact/contact.component';
import { AppDevelopmentComponent } from './service-pages/app-development/app-development.component';
import { BrandManagementComponent } from './service-pages/brand-management/brand-management.component';
import { CreativeDesignComponent } from './service-pages/creative-design/creative-design.component';
import { ITSolutionsComponent } from './service-pages/it-solutions/it-solutions.component';
import { SocialMediaMarketingComponent } from './service-pages/social-media-marketing/social-media-marketing.component';
import { WebDesigningComponent } from './service-pages/web-designing/web-designing.component';
import { PaymentComponent } from './pages/payment/payment.component';
import { DigitalMarketingComponent } from './pages/digital-marketing/digital-marketing.component';
import { WebDevelopmentComponent } from './pages/web-development/web-development.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { TermsComponent } from './pages/terms/terms.component';
import { RefundPolicyComponent } from './pages/refund-policy/refund-policy.component';
import { CoursesComponent } from './pages/courses/courses.component';
import { BlogsComponent } from './pages/blogs/blogs.component';
import { BlogDetailsComponent } from './pages/blog-details/blog-details.component';
import { ItSolutionsComponent } from './pages/it-solutions/it-solutions.component';
import { LandingPageComponent } from './pages/landing-page/landing-page.component';

const routes: Routes = [
  //{ path: '', redirectTo: 'Home', pathMatch: 'full' },
  {
    path: '', component: HomeComponent, pathMatch: 'full', data: {
      title: 'Digimiles | Best Digital Marketing company in Mohali' ,
      keywords: 'digital marketing company Mohali, Best Digital Marketing Company in Mohali, Digimiles Mohali, Website Designing Company in Mohali, Website Development Company in Chandigarh',
      descrption: "DIGIMILES connect the distance of miles between the brand and the customers. We offer digital brand strategies and are Chandigarh's best digital marketing firm, shaping the digital era.",
    }
  },
  {
    path: 'about-us', component: AboutUsComponent, data: {
      title: 'About Us | Best Digital Marketing Agency in Chandigarh | Digimiles',
      keywords: 'best digital marketing agency in Chandigarh, digital marketing solutions, app development company in Mohali',
      descrption: "With the assistance of Digimiles, you will stay ahead of the digital curve and make a difference in your niche market. Our digital marketing agency is here to help the brand succeed online, regardless of the business or target audiences.",
    }
  },
  {
    path: 'services', component: ServicesComponent, data: {
      title: 'Our Services | Digital Marketing Company in Tricity',
      descrption: 'Digital marketing is a critical element in staying connected with your audience. Working independently of, or side-by-side with you, we help keep your social community engaged.',
      keywords: 'app development, seo, digital marketing, social media marketing, best digital marketing company in Mohali ',
    }
  },
  {
    path: 'portfolio', component: PortfolioComponent, data: {
      title: 'Portfolio | Top Digital Marketing Agency | Digimiles',
      descrption: 'Enable independent, medium, and large companies in establishing a digital footprint. Our talented team work tirelessly to keep up with evolving customer preferences and patterns.',
      keywords: 'best creative design, web design company, social media management, video shoots in Mohali, Chandigarh',
    }
  },
  { path: 'payment', component: PaymentComponent },
  { path: 'landing-page', component: LandingPageComponent },
  { path: 'digital-marketing', component: DigitalMarketingComponent },
  { path: 'web-development', component: WebDevelopmentComponent },
  // { path: 'it-solutions', component: ItSolutionsComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'terms-and-conditions', component: TermsComponent },
  { path: 'refund-policy', component: RefundPolicyComponent },
  { path: 'courses-and-training', component: CoursesComponent }, 
  { path: 'blogs', component: BlogsComponent },  
  { path: 'blog-details/:blogId', component: BlogDetailsComponent },  


  {
    path: 'contact', component: ContactComponent, data: {
      title: 'Contact Us | digimiles.net',
      descrption: 'Working with a digital media agency who knows what they are doing sound obvious, contact us to get positive results. ',
      keywords: 'Digimiles Mohali, top it company in chandigarh, seo, app development, web development company near me',
    }
  },
  { path: 'services/app-development', component: AppDevelopmentComponent },
  { path: 'services/brand-management', component: BrandManagementComponent },
  { path: 'services/creative-design', component: CreativeDesignComponent },
  { path: 'services/it-solutions', component: ITSolutionsComponent },
  { path: 'services/social-media-marketing', component: SocialMediaMarketingComponent },
  { path: 'services/web-designing', component: WebDesigningComponent },
  { path: '404', component: HomeComponent },
  { path: '**', redirectTo: '/404' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration:"enabled"})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
