<div id="wrapper" class="home-page">
  <section id="banner">
    <!-- Slider -->
    <div id="main-slider" class="flexslider">
      <ul class="slides">
        <li>
          <img
            class="desktop"
            src="assets/img/slides/1.jpg"
            alt="App Development"
          />
          <img
            class="mobile"
            src="assets/img/slides/mob1.jpg"
            alt="App Development"
          />
          <div class="header__text-box">
            <h1 class="heading-primary">
              <span class="heading-primary--main">App Development</span>
              <span class="heading-primary--sub"
                >If your plans don't include app, your plans are not finished.
              </span>
            </h1>
          </div>
        </li>
        <li>
          <img
            class="desktop"
            src="assets/img/slides/slide2.webp"
            alt="Creative image of laptop and a coffee cup"
          />
          <img
            class="mobile"
            src="assets/img/slides/mob2.jpg"
            alt="Creative image of laptop and a coffee cup"
          />

          <div class="header__text-box">
            <h1 class="heading-primary">
              <span class="heading-primary--main">Creative Design</span>
              <span class="heading-primary--sub"
                >"Marketing without design is lifeless, and design without
                marketing is mute" <i> - Von Glitschka</i></span
              >
            </h1>
          </div>
        </li>
        <li>
          <img
            class="desktop"
            src="assets/img/slides/3.jpg"
            alt="Social Media Icons Images"
          />
          <img
            class="mobile"
            src="assets/img/slides/mob3.jpg"
            alt="Social Media Icons Images"
          />

          <div class="header__text-box">
            <h1 class="heading-primary">
              <span class="heading-primary--main">Digital Marketing</span>
              <span class="heading-primary--sub"
                >”If you’re not using social to drive traffic and therefore
                leads that convert to revenue, your brand is losing out. Big
                Time!” <i>– Sam Hurley</i>
              </span>
            </h1>
          </div>
        </li>
        <li>
          <img
            class="desktop"
            src="assets/img/slides/seo.webp"
            alt="Search Engine Optimization "
          />
          <img
            class="mobile"
            src="assets/img/slides/mob4.webp"
            alt="Search Engine Optimization "
          />

          <div class="header__text-box">
            <h1 class="heading-primary">
              <span class="heading-primary--main">SEO</span>
              <span class="heading-primary--sub"
                >The best place to hide a dead body is the second page of Google
                search.</span
              >
            </h1>
          </div>
        </li>
        <li>
          <img
            class="desktop"
            src="assets/img/slides/video-shoot.webp"
            alt="Camera Image for video shoots"
          />
          <img
            class="mobile"
            src="assets/img/slides/mob5.webp"
            alt="Camera Image for video shoots"
          />

          <div class="header__text-box">
            <h1 class="heading-primary">
              <span class="heading-primary--main">Video Shoots</span>
              <span class="heading-primary--sub"
                >"Great video comes from humans thinking, not equipment"
                <i>- Steve Stockman</i>
              </span>
            </h1>
          </div>
        </li>
        <li>
          <img
            class="desktop"
            src="assets/img/slides/website.webp"
            alt="Laptop image showing responsive designs"
          />
          <img
            class="mobile"
            src="assets/img/slides/mob6.webp"
            alt="Laptop image showing responsive designs"
          />

          <div class="header__text-box">
            <h1 class="heading-primary">
              <span class="heading-primary--main line-height"
                >Website Development</span
              >
              <span class="heading-primary--sub"
                >"If your business is not on the internet, then your business
                will be out of business" <i>- Bill Gates</i>
              </span>
            </h1>
          </div>
        </li>
      </ul>
    </div>
    <!-- end slider -->
  </section>

  <section class="exp_section">
    <div class="container">
      <div class="row m-0">
        <div class="col-lg-2 col-md-6 col-sm-6 col-xs-6 sec1_item">
          <div class="sec1_img">
            <img
              src="assets/img/home/digital-experience.webp"
              class="lazyload"
            />
          </div>
          <p>10+ Years of Digital Marketing Expertise</p>
        </div>
        <div class="col-lg-2 col-md-6 col-sm-6 col-xs-6 sec1_item">
          <div class="sec1_img">
            <img src="assets/img/home/keywords-logo.webp" class="lazyload" />
          </div>
          <p>Well equipped with latest technologies</p>
        </div>
        <div class="col-lg-2 col-md-6 col-sm-6 col-xs-6 sec1_item">
          <div class="sec1_img">
            <img src="assets/img/home/lead-generated.webp" class="lazyload" />
          </div>
          <p>1 Million+ Quality Leads Generated</p>
        </div>
        <div class="col-lg-2 col-md-6 col-sm-6 col-xs-6 sec1_item">
          <div class="sec1_img">
            <img src="assets/img/home/clients-logo.webp" class="lazyload" />
          </div>
          <p>Served 500+ Clients Globally</p>
        </div>
        <div class="col-lg-2 col-md-6 col-sm-6 col-xs-6 sec1_item">
          <div class="sec1_img">
            <img src="assets/img/home/customers-logo.webp" class="lazyload" />
          </div>
          <p>Happy Customers from each industry</p>
        </div>
        <div class="col-lg-2 col-md-6 col-sm-6 col-xs-6 sec1_item">
          <div class="sec1_img">
            <img src="assets/img/home/expert-team.webp" class="lazyload" />
          </div>
          <p>23+ Digital Marketing Experts</p>
        </div>
      </div>
    </div>
  </section>

  <section class="section_padding">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <img
            src="assets/img/home/about-image.jpg"
            class="img-fluid border-radius"
            alt="About Image"
          />
        </div>
        <div class="col-md-6">
          <div class="about-content wow fadeInRight">
            <h5>About Us</h5>
            <h2>Digimiles helps your business walk Miles</h2>
            <strong
              ><p>
                We bring together services for Advertising, Digital and Creative
                media and Technology and help you alleviate your business to the
                next level.
              </p></strong
            >
            <p>
              Whatever work we do is done with the goal to boost your customer
              experience which comes through an approach that focuses on smooth
              design for the work whether it’s creative or experiential.
            </p>
          </div>
          <a
            [routerLink]="['/about-us']"
            routerLinkActive="router-link-active"
            class="btn btn-primary-outline"
          >
            About us</a
          >
        </div>
      </div>
    </div>
  </section>

  <section>
    <div class="container">
      <div class="row">
        <div class="col-md-6 mb-40">
          <div class="about-content wow fadeInLeft">
            <h5>Why Choose Us</h5>
            <h2>Outstanding Digital Experience</h2>
            <strong><p>We could say we</p></strong>
            <ul>
              <li> Have the best people at work.</li>
              <li> Have worked with clients across several industries</li>
              <li> Are recommended by 85% of our clients</li>
            </ul>
            <p>But you don’t have to you can see it.</p>
          </div>
          <a
            [routerLink]="['/services']"
            routerLinkActive="router-link-active"
            class="btn btn-primary-outline"
            >All services</a
          >
        </div>
        <div class="col-md-6">
          <div
            id="carousel-example-generic"
            class="carousel slide"
            data-ride="carousel"
          >
            <!-- Indicators -->
            <ol class="carousel-indicators">
              <li
                data-target="#carousel-example-generic"
                data-slide-to="0"
                class="active"
              ></li>
              <li
                data-target="#carousel-example-generic"
                data-slide-to="1"
              ></li>
              <li
                data-target="#carousel-example-generic"
                data-slide-to="2"
              ></li>
              <li
                data-target="#carousel-example-generic"
                data-slide-to="3"
              ></li>
              <li
                data-target="#carousel-example-generic"
                data-slide-to="4"
              ></li>
            </ol>

            <!-- Wrapper for slides -->
            <div class="carousel-inner" role="listbox">
              <div class="item active">
                <iframe
                  width="100%"
                  src="https://www.youtube.com/embed/0JrceiZe_20"
                  title="YouTube video player"
                  srcdoc="<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto;border-radius:10px;}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=https://www.youtube.com/embed/0JrceiZe_20?autoplay=1><img src=assets/img/home/pyramid-thumbnail.webp alt='Pyramid VIP App Ad Video – by Digimiles'><span>▶</span></a>"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>

              <div class="item">
                <iframe
                  width="100%"
                  src="https://www.youtube.com/embed/K_ezxfQoUVI"
                  srcdoc="<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto;border-radius:10px;}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=https://www.youtube.com/embed/K_ezxfQoUVI?autoplay=1><img src=assets/img/home/landrover-thumbnail.png alt='Landrover Defender Spiti Valley Video'><span>▶</span></a>"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>

              <div class="item">
                <iframe
                  width="100%"
                  src="https://www.youtube.com/embed/fajfAAVmmXY"
                  srcdoc="<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto;border-radius:10px;}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=https://www.youtube.com/embed/fajfAAVmmXY?autoplay=1><img src=assets/img/home/cgc-thumbnail.png alt=' CGC Mohali Intro Video'><span>▶</span></a>"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>

              <div class="item">
                <iframe
                  width="100%"
                  src="https://www.youtube.com/embed/SK_Q1XZ9uQw"
                  srcdoc="<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto;border-radius:10px;}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=https://www.youtube.com/embed/SK_Q1XZ9uQw?autoplay=1><img src=assets/img/home/myudaan-thumbnail.png alt='MyUdaan Intro Video'><span>▶</span></a>"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
              <div class="item">
                <iframe
                  width="100%"
                  src="https://www.youtube.com/embed/9eAUV97Tyts"
                  srcdoc="<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto;border-radius:10px;}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=https://www.youtube.com/embed/9eAUV97Tyts?autoplay=1><img src=assets/img/home/rishtadepot-thumbnail.png alt='RishtaDepo Ad Commercial'><span>▶</span></a>"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
            </div>

            <!-- Controls -->
            <a
              class="left carousel-control"
              href="#carousel-example-generic"
              role="button"
              data-slide="prev"
            >
              <span class="fa fa-chevron-left" aria-hidden="true"></span>
              <span class="sr-only">Previous</span>
            </a>
            <a
              class="right carousel-control"
              href="#carousel-example-generic"
              role="button"
              data-slide="next"
            >
              <span class="fa fa-chevron-right" aria-hidden="true"></span>
              <span class="sr-only">Next</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="section_padding service_section pb-30">
    <div class="container">
      <div class="row">
        <div class="col-md-3 wow fadeInUp">
          <p-card [style]="{ 'margin-bottom': '2em' }">
            <img class="services-icon" src="assets/img/home/seo.png" alt="" />
            <h4>Search Engine Optimization</h4>
            <p>
              Long term strategy to rank you no. 1 on Google, Why? Because we
              all love Google.
            </p>
          </p-card>
        </div>
        <div class="col-md-3 wow fadeInUp">
          <p-card [style]="{ 'margin-bottom': '2em' }">
            <img
              class="services-icon"
              src="assets/img/home/digital-marketing.png"
              alt=""
            />
            <h4>Integrated Marketing Solutions</h4>
            <p>
              We create digital content to lev rage social media and formulate a
              strategy for your marketing goals.
            </p>
          </p-card>
        </div>
        <div class="col-md-3 wow fadeInUp">
          <p-card [style]="{ 'margin-bottom': '2em' }">
            <img
              class="services-icon"
              src="assets/img/home/it-solutions.png"
              alt=""
            />
            <h4>Customised Web Services</h4>
            <p>
              This is where code meets design. We create the best websites at
              the best rates.
            </p>
          </p-card>
        </div>
        <div class="col-md-3 wow fadeInUp">
          <p-card [style]="{ 'margin-bottom': '2em' }">
            <img
              class="services-icon"
              src="assets/img/home/video-shoots.png"
              alt=""
            />
            <h4>Video Production and Photography</h4>
            <p>
              This is where we bring your imagination to reality from product
              shoots to short films. We do it all.
            </p>
          </p-card>
        </div>
      </div>
    </div>
  </section>

  <section class="pb-30 bg-light">
    <div class="container">
      <div class="row m-0">
        <div class="col-md-12">
          <div class="aligncenter">
            <h2 class="aligncenter">Testimonials</h2>
          </div>
          <br />
        </div>
        <div class="row m-0">
          <div class="col-md-12">
            <owl-carousel-o [options]="customOptions">
              <ng-template class="mr-10" carouselSlide>
               <video controls width="100%" height="300px" muted poster="../../../assets/img/testimonials-thumbnail/aman-luthra.jpg">
                <source src="../../../assets/videos/aman-luthra.mp4" type="video/mp4">
               </video>
              </ng-template>
              <ng-template class="mr-10" carouselSlide>
                <video controls width="100%" height="300px" muted poster="../../../assets/img/testimonials-thumbnail/testimonial.jpg">
                 <source src="../../../assets/videos/ernst.mp4" type="video/mp4">
                </video>
               </ng-template>
               <ng-template class="mr-10" carouselSlide>
                <video controls width="100%" height="300px" muted poster="../../../assets/img/testimonials-thumbnail/harpreet.jpg">
                 <source src="../../../assets/videos/harpreet.mp4" type="video/mp4">
                </video>
               </ng-template>
               <ng-template class="mr-10" carouselSlide>
                <video controls width="100%" height="300px" muted poster="../../../assets/img/testimonials-thumbnail/kapson.jpg">
                 <source src="../../../assets/videos/mohit.mp4" type="video/mp4">
                </video>
               </ng-template>
               <ng-template class="mr-10" carouselSlide>
                <video controls width="100%" height="300px" muted poster="../../../assets/img/testimonials-thumbnail/neha.jpg">
                 <source src="../../../assets/videos/neha.mp4" type="video/mp4">
                </video>
               </ng-template>
               <!-- <ng-template class="mr-10" carouselSlide>
                <video controls width="100%" height="300px" muted poster="../../../assets/img/testimonials-thumbnail/skoda.jpg">
                 <source src="../../../assets/videos/pawan-skoda.mp4" type="video/mp4">
                </video>
               </ng-template> -->
               <ng-template class="mr-10" carouselSlide>
                <video controls width="100%" height="300px" muted poster="../../../assets/img/testimonials-thumbnail/amit.jpg">
                 <source src="../../../assets/videos/peoplesense.mp4" type="video/mp4">
                </video>
               </ng-template>
               <ng-template class="mr-10" carouselSlide>
                <video controls width="100%" height="300px" muted poster="../../../assets/img/testimonials-thumbnail/rattandeep.jpg">
                 <source src="../../../assets/videos/rattandeep.mp4" type="video/mp4">
                </video>
               </ng-template>
               <ng-template class="mr-10" carouselSlide>
                <video controls width="100%" height="300px" muted poster="../../../assets/img/testimonials-thumbnail/rishtadepot.jpg">
                 <source src="../../../assets/videos/rishtadepot.mp4" type="video/mp4">
                </video>
               </ng-template>
               <ng-template class="mr-10" carouselSlide>
                <video controls width="100%" height="300px" muted poster="../../../assets/img/testimonials-thumbnail/sanchit.jpg">
                 <source src="../../../assets/videos/sanchit-wtc.mp4" type="video/mp4">
                </video>
               </ng-template>
            </owl-carousel-o>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
