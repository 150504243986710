<section class="banner_section">
  <div class="container-fluid p-0">
    <div class="col-md-12 p-0">
      <img
        src="assets/img/digital-marketing-banner.jpg"
        class="img-fluid desktop"
        alt="banner"
      />
      <img src="assets/img/digital-mob.jpg" alt="" class="mobile img-fluid">

      <div class="banner_content flexslider" id="main-slider">
        <ul class="slides">
          <li>
            <p> Do you spend more time marketing rather than operating in your
              core business? Let us takeover so you can focus on things you need
              to.</p>
           
          </li>
          <li>
           <p> CTR, CPC, CPL what do these numbers mean and why are they
            important?</p>
          </li>
          <li>
            <p>My leads don’t convert! My agency isn’t transparent with me! I
              don’t have the time to handle marketing.</p>
            
          </li>
         
        </ul>
      </div>
    </div>
  </div>
</section>

<section class="form_section pb-5">
  <div class="container">
    <div class="row d-flex">
      <div class="col-md-7 col-sm-12 order">
        <div class="content_section">
          <strong
            ><p>Common problems 90% people face with digital agencies:</p>
          </strong>
          <ul>
            <li>Lack of transparency on the working</li>
            <li>Ad Agencies running just lead generation</li>
            <li>Unrealistic long-term contracts</li>
            <li>Lack of time commitment from the agency</li>
            <li>Lack of technical knowledge that causes you to get confused</li>
          </ul>
          <p>
            We know this because we had clients who faced such problems. After
            onboarding your agency, it is like an extension to your marketing
            department.
          </p>
          <p>
            Digital marketing is not as complicated as it is made. What you need
            are people who are knowledgeable enough and make you help you learn
            how you can make money using it.
          </p>
        </div>
      </div>
      <div class="col-md-5 col-sm-12 " #target>
        <div class="card get_in_touch rounded box-shadow">
          <div class="card-header rounded-top">
            <h5 class="text-bold text-white m-0">
              <i class="fa fa-pencil"></i>
              GET IN TOUCH
            </h5>
            <p class="text-white mt-3 p-0 mb-0">
              Please complete the form below and we will get back to you ASAP!
            </p>
          </div>
          <form
            [formGroup]="registerationForm"
            (ngSubmit)="submit()"
            class="p-5"
          >
            <div class="form-group">
              <label for="exampleInputEmail1" class="text-bold">Name</label>
              <input
                type="text"
                class="form-control"
                formControlName="name"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="Enter Name"
              />
              <span
                class="invalid-feedback d-block"
                *ngIf="
                  form.name.touched &&
                  registerationForm.get('name').hasError('required')
                "
                >Name is required</span
              >
            </div>
            <div class="form-group">
              <label for="exampleInputPassword1" class="text-bold">Email</label>
              <input
                type="email"
                class="form-control"
                formControlName="email"
                id="exampleInputPassword1"
                placeholder="Enter email"
              />
              <span
                class="invalid-feedback d-block"
                *ngIf="
                  form.email.touched &&
                  registerationForm.get('email').hasError('required')
                "
                >Email is required</span
              >
              <span
                class="invalid-feedback d-block"
                *ngIf="
                  form.email.touched &&
                  registerationForm.get('email').hasError('pattern') &&
                  form.email.invalid
                "
                >Email must be a valid email address</span
              >
            </div>
            <div class="form-group">
              <label for="exampleInputPassword1" class="text-bold"
                >Phone Number</label
              >
              <input
                type="number"
                class="form-control"
                formControlName="phone_number"
                id="exampleInputPassword1"
                minlength="10"
                placeholder="Enter phone number "
              />
              <span
                class="invalid-feedback d-block"
                *ngIf="
                  form.phone_number.touched &&
                  registerationForm.get('phone_number').hasError('required')
                "
                >Phone number is required</span
              >
              <span
                class="invalid-feedback d-block"
                *ngIf="
                  form.phone_number.touched &&
                  form.phone_number?.errors?.pattern &&
                  form.phone_number.invalid
                "
                >Min length should be 10 digits</span
              >
            </div>
            <p-toast [style]="{ marginTop: '80px' }"></p-toast>

            <button type="submit" class="btn btn-primary mt-3 box-shadow">
              Get Quote
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="our_portfolio">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h2 class="mt-5 mb-5 pb-5 text-uppercase text-center font-weight-bold">
          Our Recent Portfolio
        </h2>
      </div>
      <div class="col-md-12">
        <p-tabView class="responsive-tab-view" > 
          <p-tabPanel header="Happy Customers">
            <div class="row ">
              <div class="col-md-4 p-5">
                <iframe width="100%" height="280" src="https://www.youtube.com/embed/IiRZJRaekGM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
              <div class="col-md-4 p-5">
                <iframe width="100%" height="280" src="https://www.youtube.com/embed/_ZXdVv7ZzGk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
              <div class="col-md-4 p-5">
                <iframe width="100%" height="280" src="https://www.youtube.com/embed/lHrp7ecUu-M" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
              <div class="col-md-4 p-5">
                <iframe width="100%" height="280" src="https://www.youtube.com/embed/qgEJEHbAXG4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
              <div class="col-md-4 p-5">
                <iframe width="100%" height="280" src="https://www.youtube.com/embed/y04yPKRgb6U" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
              <div class="col-md-4 p-5">
                <iframe width="100%" height="280" src="https://www.youtube.com/embed/KpP7eOuPjLQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
              <div class="col-md-4 p-5">
                <iframe width="100%" height="280" src="https://www.youtube.com/embed/EQXSL0w7eO8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>  
          </p-tabPanel>
          <p-tabPanel header="Graphic Designing">
            <div class="row">
              <div class="col-md-4 p-5">
                <img src="assets/img/graphic-design/1_graphics_DIGIMILES_.jpg" class="img-fluid" alt="">
              </div>
              <div class="col-md-4 p-5">
                <img src="assets/img/graphic-design/2_graphics_DIGIMILES_.jpg" class="img-fluid" alt="">
              </div>
              <div class="col-md-4 p-5">
                <img src="assets/img/graphic-design/3_graphics_DIGIMILES_.jpg" class="img-fluid" alt="">
              </div>
              
              <div class="col-md-4 p-5">
                <img src="assets/img/graphic-design/5_graphics_DIGIMILES_.jpg" class="img-fluid" alt="">
              </div>
              
              <div class="col-md-4 p-5">
                <img src="assets/img/graphic-design/7_graphics_DIGIMILES_.jpg" class="img-fluid" alt="">
              </div>
              <div class="col-md-4 p-5">
                <img src="assets/img/graphic-design/8_graphics_DIGIMILES_.jpg" class="img-fluid" alt="">
              </div>
              <div class="col-md-4 p-5">
                <img src="assets/img/graphic-design/12_graphics_DIGIMILES_.jpg" class="img-fluid" alt="">
              </div>
              <div class="col-md-4 p-5">
                <img src="assets/img/graphic-design/13_graphics_DIGIMILES_.jpg" class="img-fluid" alt="">
              </div>
            </div>
          </p-tabPanel>
          <p-tabPanel header="Videos">
            <div class="row ">
              <div class="col-md-4 p-5">
                <iframe width="100%" height="280" src="https://www.youtube.com/embed/0JrceiZe_20" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
              <div class="col-md-4 p-5">
                <iframe width="100%" height="280" src="https://www.youtube.com/embed/fajfAAVmmXY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>  
              <div class="col-md-4 p-5">
                <iframe width="100%" height="280" src="https://www.youtube.com/embed/K_ezxfQoUVI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
              <div class="col-md-4 p-5">
                <iframe width="100%" height="280" src="https://www.youtube.com/embed/_fSLwsny8uM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
              <div class="col-md-4 p-5">
                <iframe width="100%" height="280" src="https://www.youtube.com/embed/XaUNLhlvcwk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
              <div class="col-md-4 p-5">
                <iframe width="100%" height="280" src="https://www.youtube.com/embed/SlJ_54TnO-w" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
              <div class="col-md-4 p-5">
                <iframe width="100%" height="280" src="https://www.youtube.com/embed/YOhNrYkqJ6A" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
              <div class="col-md-4 p-5">
                <iframe width="100%" height="280" src="https://www.youtube.com/embed/SK_Q1XZ9uQw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
              <div class="col-md-4 p-5">
                <iframe width="100%" height="280" src="https://www.youtube.com/embed/tL40tIAZubk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
              <div class="col-md-4 p-5">
                <iframe width="100%" height="280" src="https://www.youtube.com/embed/oAYFXr8L5VM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
              <div class="col-md-4 p-5">
                <iframe width="100%" height="280" src="https://www.youtube.com/embed/G_dgyMVFmCY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
              
            </div>  
          </p-tabPanel>
          <p-tabPanel header="Awards & Accolades">
            <div class="col-md-4 p-5">
              <img src="assets/img/awards/9_graphics_DIGIMILES_.jpg" class="img-fluid" alt="">
            </div>
            <div class="col-md-4 p-5">
              <img src="assets/img/awards/10_graphics_DIGIMILES_.jpg" class="img-fluid" alt="">
            </div>
            <div class="col-md-4 p-5">
              <img src="assets/img/awards/14_graphics_DIGIMILES_.jpg" class="img-fluid" alt="">
            </div>
            <div class="col-md-4 p-5">
              <img src="assets/img/awards/15_graphics_DIGIMILES_.jpg" class="img-fluid" alt="">
            </div>
            <div class="col-md-4 p-5">
              <img src="assets/img/awards/16_graphics_DIGIMILES_.jpg" class="img-fluid" alt="">
            </div>
            <div class="col-md-4 p-5">
              <img src="assets/img/awards/17_graphics_DIGIMILES_.jpg" class="img-fluid" alt="">
            </div>
       </p-tabPanel>
        </p-tabView>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 text-center">
        <button
          type="submit"
          class="btn btn-primary scroll-btn mt-3 box-shadow"
          (click)="scroll(target)"
        >
          Get Quote
        </button>
      </div>
    </div>
  </div>
</section>


