import { Component } from '@angular/core';
import {Validators,FormControl,FormGroup,FormBuilder} from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { QueryService } from './query.service';
import { WOW } from 'wowjs/dist/wow.min';

interface City {
  name: string,
  code: string
}
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [MessageService] 
})
export class AppComponent {
  title = 'Digimiles';
  display: boolean = false;
  queries: City[];
  selectedQuery: City;
  submitted = false;
  error: {};
  userform: FormGroup;   

  constructor(private fb: FormBuilder,private queryservice: QueryService, private router: Router, private messageService: MessageService ) {
    this.queries = [
      { name: 'Digital Marketing', code: 'DM' },
      { name: 'Website Development', code: 'WD' },
      { name: 'App Development', code: 'AD' },
    ];
  }

  ngAfterViewInit(){
    new WOW().init();
  }
    
  ngOnInit() {
      this.userform = this.fb.group({
          'name': new FormControl('', Validators.required),
          'email': new FormControl('', Validators.required),
          'number': new FormControl('', [Validators.required, Validators.minLength(10)]),
          'query' : new FormControl('',Validators.required),
          'message': new FormControl('',Validators.required)
      });
  }

  onSubmit(value: string) {
      this.submitted = true;
      const data = {
        name: this.userform.value.name,
        email: this.userform.value.email,
        number: this.userform.value.number,
        query: this.userform.value.query.name,
        message: this.userform.value.message,
      };
      this.showSuccess();                                              
      this.display = false;
      this.userform.reset();
       this.queryservice.contactForm(data).subscribe(
        data => data,
        error => this.error = error
      );
  }

  showDialog() {
    this.display = true;
  }

  closeDialog(){
    this.display = false;
  }

  showSuccess() {
    this.messageService.add({severity:'success', summary: 'Email Confirmation', detail:'Thanks your email has been submitted.'});
  }

}
