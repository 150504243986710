<div class="topbar">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <p class="pull-left hidden-xs">
            <i class="fa fa-clock-o"></i
            ><span>Mon - Sat 9.30 - 6.30. Sunday CLOSED</span>
          </p>
          <p class="pull-right hidden-xs text-white">
            <i class="fa fa-phone text-white">  </i>   <span> Call Us: 9041010709</span>
          </p>
        </div>
      </div>
    </div>
  </div>
  <!-- start header -->
  <header>
    <div class="navbar navbar-default navbar-static-top">
      <div class="container">
        <div class="navbar-header">
          <button
            type="button"
            class="navbar-toggle"
            data-toggle="collapse"
            data-target=".navbar-collapse"
          >
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
          </button>
          <a class="navbar-brand" [routerLink]="['']"
            ><img src="assets/img/logo/digimiles-logo.png"  height="60" alt="Digimiles Logo"
          /></a>
        </div>
        <div class="navbar-collapse collapse">
          <ul class="nav navbar-nav">
            <li [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact: true}" data-toggle="collapse" data-target=".navbar-collapse"><a [routerLink]="['']" >Home</a></li>
            <li [routerLinkActive]="['active']" ><a [routerLink]="['/about-us']" data-toggle="collapse" data-target=".navbar-collapse">About Us</a></li>
            <li [routerLinkActive]="['active']" ><a [routerLink]="['/services']" data-toggle="collapse" data-target=".navbar-collapse">Services</a></li>
            <li [routerLinkActive]="['active']" ><a [routerLink]="['/courses-and-training']" data-toggle="collapse" data-target=".navbar-collapse">Courses & Training</a></li>

            <li [routerLinkActive]="['active']" ><a [routerLink]="['/portfolio']" data-toggle="collapse" data-target=".navbar-collapse">Portfolio</a></li>
            <li [routerLinkActive]="['active']" ><a [routerLink]="['/blogs']" data-toggle="collapse" data-target=".navbar-collapse">Blogs</a></li>
            <li [routerLinkActive]="['active']" ><a [routerLink]="['/contact']" data-toggle="collapse" data-target=".navbar-collapse">Contact</a></li>
          </ul>
        </div>
      </div>
    </div>
  </header>
  <!-- end header -->