
<section class="banner">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-7">
          <div class="header_content mt-3">
            <h2 class="text-capitalize mt-4 ">
              DO YOU WISH TO <br>
              <span class="tw"></span>
            </h2>
            <p>At DIGIMILES, we understand your business needs & help connect potential customers worldwide. We develop customized Websites/Applications with high-tech functionality and features.</p>
           
            
          </div>
        </div>
       
        <div class="col-md-5" #target>
          <div class="card get_in_touch rounded box-shadow">
            <div class="card-header text-center rounded-top">
              <h5 class="text-bold m-0">
                <i class="fa fa-pencil"></i>
                GET IN TOUCH
              </h5>
            </div>
            <form
              [formGroup]="registerationForm"
              (ngSubmit)="submit()"
              class="p-5"
            >
              <div class="form-group">
                <label for="exampleInputEmail1" class="text-bold">Name</label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="name"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="Enter Name"
                />
                <span
                  class="invalid-feedback d-block"
                  *ngIf="
                    form.name.touched &&
                    registerationForm.get('name').hasError('required')
                  "
                  >Name is required.</span
                >
              </div>
              <div class="form-group">
                <label for="exampleInputPassword1" class="text-bold">Email</label>
                <input
                  type="email"
                  class="form-control"
                  formControlName="email"
                  id="exampleInputPassword1"
                  placeholder="Enter email"
                />
                <span
                  class="invalid-feedback d-block"
                  *ngIf="
                    form.email.touched &&
                    registerationForm.get('email').hasError('required')
                  "
                  >Email is required.</span
                >
                <span
                  class="invalid-feedback d-block"
                  *ngIf="
                    form.email.touched &&
                    registerationForm.get('email').hasError('pattern') &&
                    form.email.invalid
                  "
                  >Email must be a valid email address.</span
                >
              </div>
              <div class="form-group">
                <label for="exampleInputPassword1" class="text-bold"
                  >Phone Number</label
                >
                <input
                  type="number"
                  class="form-control"
                  formControlName="phone_number"
                  id="exampleInputPassword1"
                  minlength="10"
                  placeholder="Enter phone number "
                />
                <span
                  class="invalid-feedback d-block"
                  *ngIf="
                    form.phone_number.touched &&
                    registerationForm.get('phone_number').hasError('required')
                  "
                  >Phone number is required.</span
                >
                <span
                  class="invalid-feedback d-block"
                  *ngIf="
                    form.phone_number.touched &&
                    form.phone_number?.errors?.pattern &&
                    form.phone_number.invalid
                  "
                  >Min length should be 10 digits.</span
                >
              </div>
              <p-toast [style]="{ marginTop: '80px' }"></p-toast>
  
              <button type="submit" class="btn btn-primary mt-3 box-shadow">
                Get Quote
              </button>
            </form>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <h3 class="text-center mt-5 display-3">Responsive Web Design</h3>
        </div>
        <div class="col-12 bottom-banner">
          <div class="img-contain">
            <div class="img img1 mt-6">
              <h5>Automobile</h5>
              <img src="assets/img/landing-page/automobile.png" class="img-fluid" alt="mobile">
            </div>
            <div class="img img2 mt-6">
              <h5>Pharmacy App</h5>
              <img src="assets/img/landing-page/pharmacy-app.png" class="img-fluid" alt="mobile">
            </div>
            <div class="img img3 mt-6">
              <h5>Ecommerce</h5>
              <img src="assets/img/landing-page/ecommerce.png" class="img-fluid" alt="mobile">
            </div>
            <div class="img img4 mt-mob-6">
              <h5>Jewellery App</h5>
              <img src="assets/img/landing-page/jewellery-app.png" class="img-fluid" alt="mobile">
            </div>
            <div class="img img5 mt-md-6">
              <h5>Education</h5>
              <img src="assets/img/landing-page/education.png" class="img-fluid" alt="mobile">
            </div>
          </div>
          <div class="anime-div1">
            <img src="assets/img/landing-page/shape-3.png" class="img-fluid" alt="anime">
          </div>
          <div class="anime-div2">
            <img src="assets/img/landing-page/shape-4.png" class="img-fluid" alt="anime">
          </div>
        </div>
      </div>
    </div>
  </section>
  
  <section class="client" style="padding: 2rem 0 2rem !important">
    <div class="container">
      <div class="row">
        <div class="head text-center">
          <h3 class="display-3">Featured Clients</h3>
          <p>Some of our happy clients who enjoy working with us!</p>
        </div>
      </div>
      <div class="row gy-5">
        <!-- <div class="col-12">
          <owl-carousel-o [options]="customOptions">

            <ng-container *ngFor="let slide of slidesStore">
              <ng-template carouselSlide >
                <img [src]="slide.src" [alt]="slide.alt" class="img-fluid">
              </ng-template>
            </ng-container>
        
          </owl-carousel-o>
        </div> -->
        <div class="col-6 col-md-4 col-lg-3 col-xs-6">
          <div class="img">
            <img src="assets/img/landing-page/clients/avanya-healthcare.png" class="img-fluid p-5" alt="client">
          </div>
        </div>
        <div class="col-6 col-md-4 col-lg-3 col-xs-6" >
          <div class="img">
            <img src="assets/img/landing-page/clients/catalyst.png" class="img-fluid p-5" alt="client">
          </div>
        </div>
        <div class="col-6 col-md-4 col-lg-3 col-xs-6" >
          <div class="img">
            <img src="assets/img/landing-page/clients/drkunal.png" class="img-fluid p-5" alt="client">
          </div>
        </div>
        <div class="col-6 col-md-4 col-lg-3 col-xs-6" >
          <div class="img">
            <img src="assets/img/landing-page/clients/gogopogoart.png" class="img-fluid p-5" alt="client">
          </div>
        </div>
        <div class="col-6 col-md-4 col-lg-3 col-xs-6" >
          <div class="img">
            <img src="assets/img/landing-page/clients/krishna-skoda.png" class="img-fluid p-5" alt="client">
          </div>
        </div>
        <div class="col-6 col-md-4 col-lg-3 col-xs-6" >
          <div class="img">
            <img src="assets/img/landing-page/clients/laaygos.png" class="img-fluid p-5" alt="client">
          </div>
        </div>
        <div class="col-6 col-md-4 col-lg-3 col-xs-6" >
          <div class="img">
            <img src="assets/img/landing-page/clients/paragon.png" class="img-fluid p-5" alt="client">
          </div>
        </div>
        <div class="col-6 col-md-4 col-lg-3 col-xs-6" >
          <div class="img">
            <img src="assets/img/landing-page/clients/paragon-kids.png" class="img-fluid p-5" alt="client">
          </div>
        </div>
        <div class="col-6 col-md-4 col-lg-3 col-xs-6" >
          <div class="img">
            <img src="assets/img/landing-page/clients/peoplesense.png" class="img-fluid p-5" alt="client">
          </div>
        </div>
        <div class="col-6 col-md-4 col-lg-3 col-xs-6" >
          <div class="img">
            <img src="assets/img/landing-page/clients/rishtasite.png" class="img-fluid p-5" alt="client">
          </div>
        </div>
        <div class="col-6 col-md-4 col-lg-3 col-xs-6" >
          <div class="img">
            <img src="assets/img/landing-page/clients/shakti-jewellers.png" class="img-fluid p-5" alt="client">
          </div>
        </div>
        <div class="col-6 col-md-4 col-lg-3 col-xs-6" >
          <div class="img">
            <img src="assets/img/landing-page/clients/tata-motors.png" class="img-fluid p-5" alt="client">
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="section" id="features">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="sec_main_head_title mx-auto text-center">
            <h2 class="display-3">What We Do?</h2>
            <p class="mt-3 w-75 mx-auto">
              Everything! From sales-oriented websites to state-of-the-art web apps for service-based businesses. We can build anything you dream. We are fully equipped to realize your most ambitious visions and unleash your unleash your full potential. With us, you can be truly limitless.
            </p>
          </div>
        </div>
      </div>
      <div class="row mt-5 align-items-center">
        <div class="col-lg-6">
          <div class="content_features mx-auto mt-3">
            <p class="content_small_title mb-0 text-uppercase">
              <span></span>Perfect Design
            </p>
            <h3 class="content_main_title mb-0 mt-4">
              WEBSITE DEVELOPMENT/DESIGNING 
            </h3>
            <p class="mt-4">
              As a website development company, we aim to serve maximum businesses from different platforms with our world-class web development services. We at DIGIMILES render a wide range of website development services to match the different needs of businesses. You can approach us with any of the services that you need.
            </p>
            <ul>
              <li>Custom Web Development</li>
              <li>Web Portal Development </li>
              <li>E-commerce Web Development</li>
              <li>Maintenance & Support</li>
            </ul>
            <!-- <div class="mt-4 pt-3">
              <a href="#" class="btn btn-custom btn-rounded">Explore</a>
            </div> -->
          </div>
        </div>
        <div class="col-lg-6">
          <div class="side_img_content mt-3">
            <img
              src="assets/img/features-one.png"
              alt="Vector image on perfect design"
              class="img-fluid mx-auto d-block"
            />
          </div>
        </div>
      </div>
      <div class="features_divider"></div>
      <div class="row mt-3 align-items-center">
        <div class="col-lg-6">
          <div class="side_img_content mt-3">
            <img
              src="assets/img/features-two.png"
              alt="Vector image on user interface"
              class="img-fluid mx-auto d-block"
            />
          </div>
        </div>
        <div class="col-lg-6">
          <div class="content_features mx-auto mt-3">
            <p class="content_small_title mb-0 text-uppercase">
              <span></span>User Interface
            </p>
            <h3 class="content_main_title mb-0 mt-4">
              APP DEVELOPMENT
            </h3>
            <p class="mt-4">
              DIGIMILES is a cutting-edge mobile application development company in Mohali, Punjab that develops ingenious mobile apps that make your business easily accessible on iPhones and Androids. The capability to meet your needs comes from our seasoned & skilled app experts, who have extensive experience in worldwide app solution services and product engineering industries.
            </p>
            <ul>
              <li>Android </li>
              <li>iOS</li>
              <li>Cross Platform </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="section">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="sec_main_head_title mx-auto text-center">
            <h2 class="display-3">Clients Testimonials</h2>
          <p>Take a look at what our happy clients say about us</p>

          </div>
        </div>
      </div>
      <div class="row mt-5 align-items-center">
        <div class="col-md-4 p-5">
          <iframe
            width="100%"
            height="280"
            src="https://www.youtube.com/embed/IiRZJRaekGM"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <div class="col-md-4 p-5">
          <iframe
            width="100%"
            height="280"
            src="https://www.youtube.com/embed/_ZXdVv7ZzGk"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <div class="col-md-4 p-5">
          <iframe
            width="100%"
            height="280"
            src="https://www.youtube.com/embed/lHrp7ecUu-M"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <div class="col-md-4 p-5">
          <iframe
            width="100%"
            height="280"
            src="https://www.youtube.com/embed/qgEJEHbAXG4"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <div class="col-md-4 p-5">
          <iframe
            width="100%"
            height="280"
            src="https://www.youtube.com/embed/y04yPKRgb6U"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <div class="col-md-4 p-5">
          <iframe
            width="100%"
            height="280"
            src="https://www.youtube.com/embed/KpP7eOuPjLQ"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <div class="col-md-4 p-5">
          <iframe
            width="100%"
            height="280"
            src="https://www.youtube.com/embed/EQXSL0w7eO8"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <div class="col-md-4 p-5">
          <iframe
            width="100%"
            height="280"
            src="https://www.youtube.com/embed/KpP7eOuPjLQ"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <div class="col-md-4 p-5">
          <iframe
            width="100%"
            height="280"
            src="https://www.youtube.com/embed/ctAUFT-RRGc"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <div class="col-md-4 p-5">
          <iframe
            width="100%"
            height="280"
            src="https://www.youtube.com/embed/KbDKgIdbmbo"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <div class="col-md-4 p-5">
          <iframe
            width="100%"
            height="280"
            src="https://www.youtube.com/embed/ihzhlsqSZqg"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
      
    </div>
  </section>

  <section class="section contact_detail_bg">
		<div class="container">
			<div class="row">
				<div class="col-md-4">
					<div class="contact_detail mt-3 text-center">
						<div class="contact_icon">
							<i class="fa fa-envelope"></i>
						</div>
						<div class="contact_name mt-3">
							<h5 class="fw-bold mb-2">Email</h5>
							<p >jasmeetsingh@digimiles.net</p>
						</div>
					</div>
				</div>
				<div class="col-md-4">
					<div class="contact_detail mt-3 text-center">
						<div class="contact_icon">
							<i class="fa fa-phone"></i>
						</div>
						<div class="contact_name mt-3">
							<h5 class="fw-bold mb-2">Telephone</h5>
							<p >+91 9041020709, +91 9906624847</p>
						</div>
					</div>
				</div>
				<div class="col-md-4">
					<div class="contact_detail mt-3 text-center">
						<div class="contact_icon">
							<i class="fa fa-clock-o"></i>
						</div>
						<div class="contact_name mt-3">
							<h5 class="fw-bold mb-2">Business Hours</h5>
							<p class="mb-0">Monday To Saturday</p>
							<p >9:30am To 6:30pm (GMT)</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>