import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

declare var paypal;

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {
  registerForm: FormGroup;
  currencies = ['USD', 'EUR', 'CAD', 'AUD', 'INR'];
  amount = [399, 499, 599];
  paidFor = false;
  isPaymentDone = false;
  isError = false;
  @ViewChild('paypal', { static: true }) paypalElement: ElementRef;

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.createPaymentForm();

  }
  renderPayPal() {
    paypal
      .Buttons({
        createOrder: (data, actions) => {
          return actions.order.create({
            purchase_units: [
              {
                description: this.form.description.value,
                amount: {
                  currency_code: this.form.currency_code.value,
                  value: this.form.amount.value
                }
              }
            ]
          });
        },
        onApprove: async (data, actions) => {
          const order = await actions.order.capture();
          this.isPaymentDone = true;
          console.log(order);
        },
        onError: err => {
          console.log(err);
          this.isError = true;
        }
      })
      .render(this.paypalElement.nativeElement);
  }

  createPaymentForm() {
    this.registerForm = this.formBuilder.group({
      description: ['', Validators.required],
      amount: ['', Validators.required],
      currency_code: [null, Validators.required]
    });
  }
  get form() { return this.registerForm.controls; }

  setAmount(amount) {
    this.form.amount.setValue(amount);
  }
  changeCurrency() {
    this.loadExternalMicroApp(this.form.currency_code.value).then(res => {
      this.renderPayPal();
    });
  }

  loadExternalMicroApp(currency) {
   // const secret = 'AeAVES7uGoMTOBNSi-tORuMJ8tTDpVLkv4a-VQW6p72sfb430X5SFdutnFAyRjzU73i6NwAKyu5oky5P';
    const secret ='AT3wxJ7dV5XIJk_c1IJCOHFAVIaxhqxPqFXawg8oMKXrWxHZW5rN0oU4cfFTSeKK2h3agQRMjGODeBxt';
    const url = `https://www.paypal.com/sdk/js?currency=${currency}&client-id=${secret}`;
    this.removeScript();
    return new Promise((resolve, reject) => {
      // resolve if already loaded
      // load script
      const script: any = document.createElement('script');
      script.type = 'text/javascript';
      script.src = url;
      script.async = true;
      script.onload = () => {
        resolve(0);
      };
      script.onerror = (error) => resolve({ loaded: false, status: 'error' });
      // finally append the script tag in the DOM
      document.getElementsByTagName('head')[0].appendChild(script);
    });
  }
  removeScript() {
    const head = document.getElementsByTagName('head')[0];
    const scripts = head.getElementsByTagName('script');
    if (scripts.length > 0) {
      head.removeChild(scripts[0]);
    }

  }

}
