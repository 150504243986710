import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { MessageService } from 'primeng/api';
import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'app-digital-marketing',
  templateUrl: './digital-marketing.component.html',
  styleUrls: ['./digital-marketing.component.css']
})
export class DigitalMarketingComponent implements OnInit {
  registerationForm:FormGroup;
  msg: string = null;
  data:any
  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private httpservice: HttpService,
    private messageService: MessageService
    ) { }

    ngOnInit(){

      this.registerationForm = this.formBuilder.group({
       email: ['', [Validators.required, Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)    ]],
       name: ['', Validators.required],
       phone_number: ['', [Validators.required, Validators.pattern('[0-9 ]{10,15}' )]],
       type: ['Digital Marketing']
 
     });          
 
 }

 scroll(el: HTMLElement) {
  el.scrollIntoView({behavior: 'smooth'});
}

get form() { return this.registerationForm.controls; }

submit(){
  // console.log(this.registerationForm.value);

  if (this.registerationForm.invalid) {
    this.registerationForm.get('email').markAsTouched();
    this.registerationForm.get('name').markAsTouched();
    this.registerationForm.get('phone_number').markAsTouched();
    return;
    }

  const payload = {
    name: this.registerationForm.value.name,
    email: this.registerationForm.value.email,
    phone_number: this.registerationForm.value.phone_number,
    type: this.registerationForm.value.type
    
  }
  
    this.httpservice.formData(payload)
      .subscribe((res) => {
        console.log(res);
      });
      this.showSuccess();
      this.registerationForm.reset();

}

showSuccess() {
  this.messageService.add({severity:'success', summary: 'Request Submitted', detail:'Our team will get back to you soon.'});

}

customOptions: OwlOptions = {
  loop: true,
  mouseDrag: false,
  pullDrag: false,
  dots: false,
  margin: 20,
  autoplay: true,
  autoplayHoverPause: true,
  navSpeed: 700,
  navText : ["<i class='fa fa-arrow-left'></i>","<i class='fa fa-arrow-right'></i>"],
  responsive: {
    0: {
      items: 1
    },
    400: {
      items: 1
    },
    740: {
      items: 1
    },
    940: {
      items: 1
    }
  },
  nav: false
}

}
