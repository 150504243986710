import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

declare var $: any;

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.css']
})
export class PortfolioComponent implements OnInit {

  webGallery = [
    {
      img: 'assets/img/website/paragon_senior.jpeg',
      link: 'https://paragon71.com/senior-secondary-school'
    },
    {
      img: 'assets/img/website/paragon_kids.jpeg',
      link: 'https://paragon71.com/paragon-kids/'

    },
    {
      img: 'assets/img/website/centura_immigration.jpeg',
      link: 'https://centuraimmigration.ca/'

    },
    {
      img: 'assets/img/website/drrajesh.jpeg',
      link: 'https://drrajeshvijay.com/'

    },
    {
      img: 'assets/img/website/laaygos_healthcare.jpeg',
      link: 'https://laaygoshealthcare.com/'

    },
    {
      img: 'assets/img/website/people_sense.jpeg',
      link: 'https://peoplesense.in/'

    }, 
    {
      img: 'assets/img/website/pursuit_overseas.jpeg',
      link: 'http://thepursuitoverseas.in/'

    }, 
    {
      img: 'assets/img/website/4.jpg',
      link: 'http://teamtdot.com'

    },
    {
      img: 'assets/img/website/sukhsar.jpg',
      link: 'http://sukhsar.com/'
    },
    {
      img: 'assets/img/website/6.jpg',
      link: 'https://wonneinternational.com'

    },
    {
      img: 'assets/img/website/7.jpg',
      link: 'http://barotafarm.com'      
    }
  ]

  videoGallery = [
    {
      img: 'assets/img/video-creatives/skoda.jpg',
      link: 'https://youtu.be/SlJ_54TnO-w'
    },
    {
      img: 'assets/img/video-creatives/raymond.jpg',
      link: 'https://youtu.be/_fSLwsny8uM'
    },
    {
      img: 'assets/img/video-creatives/myudaan.jpg',
      link: 'https://youtu.be/SK_Q1XZ9uQw'

    },
    {
      img: 'assets/img/video-creatives/rishta1.jpg',
      link: 'https://youtu.be/mO7ow0A4pKI'

    },
    {
      img: 'assets/img/video-creatives/jaguar.jpg',
      link: 'https://youtu.be/PLZHGG9iImY'

    },
    {
      img: 'assets/img/video-creatives/dada.jpg',
      link: 'https://youtu.be/kENyqKhd1us'
      
    },
    {
      img: 'assets/img/video-creatives/rishta2.jpg',
      link: 'https://youtu.be/XaUNLhlvcwk'

    },
    // {
    //   img: 'assets/img/video-creatives/8.jpg',
    // },
    // {
    //   img: 'assets/img/video-creatives/9.jpg',
    // },
    // {
    //   img: 'assets/img/video-creatives/10.jpg',
    // },
    // {
    //   img: 'assets/img/video-creatives/11.jpg',
    // },
  ]

  graphics = [
    {
      img: 'assets/img/creatives/21.jpg',
    },
    {
      img: 'assets/img/creatives/22.jpg',
    },
    {
      img: 'assets/img/creatives/23.jpg',
    },
    {
      img: 'assets/img/creatives/24.jpg',
    },
    {
      img: 'assets/img/creatives/25.jpg',
    },
    {
      img: 'assets/img/creatives/28.jpg',
    },
    {
      img: 'assets/img/creatives/31.jpg',
    },
    {
      img: 'assets/img/creatives/26.jpg',
    },
    {
      img: 'assets/img/creatives/27.jpg',
    },
    {
      img: 'assets/img/creatives/30.jpg',
    },
    {
      img: 'assets/img/creatives/33.jpg',
    },
    {
      img: 'assets/img/creatives/29.jpg',
    },
    {
      img: 'assets/img/creatives/32.jpg',
    },
    {
      img: 'assets/img/creatives/ernst1.jpg',
    }, 
    {
      img: 'assets/img/creatives/10.jpg',
    },
    {
      img: 'assets/img/creatives/11.jpg',
    },
    {
      img: 'assets/img/creatives/12.jpg',
    },
    {
      img: 'assets/img/creatives/13.jpg',
    },
    {
      img: 'assets/img/creatives/14.jpg',
    },
    {
      img: 'assets/img/creatives/15.jpg',
    },
    {
      img: 'assets/img/creatives/16.jpg',
    },
    {
      img: 'assets/img/creatives/17.jpg',
    },
    {
      img: 'assets/img/creatives/18.jpg',
    },
    {
      img: 'assets/img/creatives/19.jpg',
    },
    {
      img: 'assets/img/creatives/20.jpg',
    },
    {
      img: 'assets/img/creatives/1.jpg',
    },
    {
      img: 'assets/img/creatives/2.jpg',
    },
    {
      img: 'assets/img/creatives/3.jpg',
    },
    {
      img: 'assets/img/creatives/classic.jpg',
    },
    {
      img: 'assets/img/creatives/4.jpg',
    },
    {
      img: 'assets/img/creatives/5.jpg',
    },
    {
      img: 'assets/img/creatives/ernst2.jpg',
    },
    {
      img: 'assets/img/creatives/digimiles.jpg',
    },
    {
      img: 'assets/img/creatives/7.jpg',
    },
    {
      img: 'assets/img/creatives/sham.jpg',
    },
    {
      img: 'assets/img/creatives/randhawa.jpg',
    },
    {
      img: 'assets/img/creatives/9.jpg',
    }
  ]

  seo = [
    {
      img: 'assets/img/seo/1.jpg',
      link: 'https://studyrevolution.com'
    },
    
  ]

  smm = [
    {
      img: 'assets/img/smm/1.jpg',
      link: 'https://www.facebook.com/CICTorontoImmigration-110439113640720'

    },
    {
      img: 'assets/img/smm/4.jpg',
      link: 'https://www.facebook.com/salujamotorservices'

    },
    {
      img: 'assets/img/smm/3.jpg',
      link: 'https://www.facebook.com/InsigniaCollege'

    },
    {
      img: 'assets/img/smm/2.jpg',
      link: 'https://www.facebook.com/ernst.pharmacia.panchkula'
    },
    {
      img: 'assets/img/smm/5.jpg',
      link: 'https://www.facebook.com/barotafarm'
    },
    {
      img: 'assets/img/smm/6.jpg',
      link: 'https://www.facebook.com/DadaMotors.Jaguar'
    },
    {
      img: 'assets/img/smm/7.jpg',
      link: 'https://www.facebook.com/LandRover.DadaMotors'
    },
    {
      img: 'assets/img/smm/8.jpg',
      link: 'https://www.facebook.com/PeopleSenseconsultant'
    },
    {
      img: 'assets/img/smm/9.jpg',
      link: 'https://www.facebook.com/RANDHAWAMOTORS81'
    },
    {
      img: 'assets/img/smm/10.jpg',
      link: 'https://www.facebook.com/rishtadepotcom'
    },
    {
      img: 'assets/img/smm/11.jpg',
      link: 'https://www.facebook.com/shamfashions.mohali'
    },
   
  ]

  loadScript(url: string) {
    const body = <HTMLDivElement>document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = false;
    script.defer = true;
    body.appendChild(script);
  }

  // sectionHide: boolean = true;

  constructor() {}

  ngOnInit() {

    // this.sectionHide = false;
    //this.loadScript('assets/js/jquery.isotope.min.js');
    // this.loadScript('/assets/js/jquery.flexslider.js');
    // this.loadScript('/assets/js/jquery.fancybox.pack.js');
    // this.loadScript('/assets/js/jquery.fancybox-media.js');
    // this.loadScript('/assets/js/animate.js');
    // this.loadScript('/assets/js/modernizr.custom.js');
    // this.loadScript('/assets/js/jquery.isotope.min.js');
    // this.loadScript('/assets/js/jquery.magnific-popup.min.js');
    // this.loadScript('/assets/js/animate.js');
    // this.loadScript('/assets/js/custom.js');

    $(document).ready(function(){
      var $container = $('#isotope-gallery-container');
		var $filter = $('.filter');
		$(window).load(function () {
		// Initialize Isotope
		$container.isotope({
			itemSelector: '.gallery-item-wrapper'
		});
		$('.filter a').click(function () {
			var selector = $(this).attr('data-filter');
			$container.isotope({ filter: selector });
			return false;
		});
		$filter.find('a').click(function () {
			var selector = $(this).attr('data-filter');
			$filter.find('a').parent().removeClass('active');
			$(this).parent().addClass('active');
		});
		});
		// $(window).smartresize(function () {
		// $container.isotope('reLayout');
    // });
    })

    
  }

}
